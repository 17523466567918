import {
  emptyFill,
  emptyLine,
  UIOrigins,
  AutoCursorModes,
  UILayoutBuilders,
  UIElementBuilders,
  AxisTickStrategies,
  AxisScrollStrategies,
  synchronizeAxisIntervals,
} from "@arction/lcjs";
import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { iChannel } from "../../interfaces";
import { generateRandomID, lc } from "../../utils/helperFunctions";
import { ChartDataType, WEBSOCKET_URL } from "../../utils/constants";
import "./PatientVitals.css";

const PatientVitals = () => {
  const TIME_DOMAIN = 10 * 1000;
  const SAMPLE_RATE = 256;

  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const lastDataReceivedTime = useRef(window.performance.now());

  const channels: iChannel[] = [
    {
      shortName: "ECG/EKG",
      name: "Electrocardiogram",
      type: "ecg",
      dataSet: [],
      yStart: -50,
      yEnd: 160,
    },
    {
      shortName: "Pulse Rate",
      name: "Pleth",
      type: "pulse",
      dataSet: [],
      yStart: -200,
      yEnd: 200,
    },
    {
      shortName: "Respiratory rate",
      name: "Resp",
      type: "resp",
      dataSet: [],
      yStart: -150,
      yEnd: 150,
    },
    {
      shortName: "NIBP",
      name: "Blood pressure",
      type: "bloodPressure",
      dataSet: [],
      yStart: 50,
      yEnd: 200,
    },
  ];

  const socketRef = useRef<WebSocket | null>(null);

  const closeWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      console.log("WebSocket connection closed");
    }
  };

  const createCharts = () => {
    const layoutCharts = document.createElement("div");
    layoutCharts.style.display = "flex";
    layoutCharts.style.flexDirection = "column";

    const chartList = channels.map((channel, i) => {
      const container = document.createElement("div");
      layoutCharts.append(container);
      if (i === channels.length - 1) {
        container.style.minHeight = "160px";
      } else {
        container.style.minHeight = "220px";
      }
      const chart = lc
        .ChartXY({ container })
        .setPadding({ bottom: 4, top: 4, right: 200, left: 10 })
        .setMouseInteractions(false)
        .setAutoCursorMode(AutoCursorModes.disabled);

      const axisX = chart.getDefaultAxisX().setMouseInteractions(false);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const axisY = chart
        .getDefaultAxisY()
        .setMouseInteractions(false)
        .setInterval({ start: channel.yStart, end: channel.yEnd })
        .setTickStrategy(AxisTickStrategies.Empty)
        .setStrokeStyle(emptyLine);

      if (i > 0) {
        chart.setTitleFillStyle(emptyFill);
      } else {
        let tFpsStart = window.performance.now();
        let frames = 0;
        let fps = 0;
        const recordFrame = () => {
          frames++;
          const tNow = window.performance.now();
          fps = 1000 / ((tNow - tFpsStart) / frames);
          requestAnimationFrame(recordFrame);

          chart.setTitle(`Medical Dashboard (FPS: ${fps.toFixed(1)})`);
        };
        requestAnimationFrame(recordFrame);
        setInterval(() => {
          tFpsStart = window.performance.now();
          frames = 0;
        }, 5000);
      }

      if (i === channels.length - 1) {
        axisX
          .setTickStrategy(AxisTickStrategies.Time, (ticks: any) =>
            ticks
              .setMajorTickStyle((majorTicks: any) =>
                majorTicks
                  .setLabelFillStyle(emptyFill)
                  .setTickStyle(emptyLine)
                  .setTickLength(0)
                  .setTickPadding(0)
              )
              .setMinorTickStyle((minorTicks: any) =>
                minorTicks
                  .setLabelFillStyle(emptyFill)
                  .setTickStyle(emptyLine)
                  .setTickLength(0)
                  .setTickPadding(0)
              )
          )
          .setStrokeStyle(emptyLine)
          .setScrollStrategy(undefined);
      } else {
        axisX
          .setTickStrategy(AxisTickStrategies.Time)
          .setInterval({ start: -TIME_DOMAIN, end: 0, stopAxisAfter: false })
          .setScrollStrategy(AxisScrollStrategies.progressive);
      }
      return chart;
    });

    const uiList = chartList.map((chart, i) => {
      const axisX = chart.getDefaultAxisX();
      const axisY = chart.getDefaultAxisY();
      const channel = channels[i];
      const ui = chart
        .addUIElement(UILayoutBuilders.Column, chart.coordsRelative)
        .setBackground((background: any) =>
          background.setFillStyle(emptyFill).setStrokeStyle(emptyLine)
        )
        .setMouseInteractions(false)
        .setVisible(false);

      ui.addElement(UIElementBuilders.TextBox).setText(channel.shortName);
      ui.addElement(UIElementBuilders.TextBox)
        .setText(channel.name)
        .setTextFont((font) => font.setSize(10));

      const labelSampleRate = ui
        .addElement(UIElementBuilders.TextBox)
        .setText("")
        .setTextFont((font: any) => font.setSize(10));

      let labelEcgHeartRate;
      let labelBpmValue;
      let labelBloodPIValue;
      let labelMinMaxBPValue;
      let labelMeanBPValue;
      let labelRespiratoryValue;

      if (channel.name === "Electrocardiogram") {
        labelEcgHeartRate = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(36));
      }
      if (channel.name === "Pleth") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const labelBpm = ui
          .addElement(UIElementBuilders.TextBox)
          .setMargin({ top: 10 })
          .setText("SPO2");
        labelBpmValue = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(36));
        labelBloodPIValue = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(12));
      }
      if (channel.name === "Blood pressure") {
        labelMinMaxBPValue = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(36));
        labelMeanBPValue = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(36));
      }
      if (channel.name === "Resp") {
        labelRespiratoryValue = ui
          .addElement(UIElementBuilders.TextBox)
          .setText("")
          .setTextFont((font: any) => font.setSize(36));
      }

      const positionUI = () => {
        ui.setVisible(true)
          .setPosition(
            chart.translateCoordinate(
              { x: axisX.getInterval().end, y: axisY.getInterval().end },
              chart.coordsAxis,
              chart.coordsRelative
            )
          )
          .setOrigin(UIOrigins.LeftTop)
          .setMargin({ left: 10 });
      };
      chart.onResize(positionUI);

      return {
        labelEcgHeartRate,
        labelSampleRate,
        labelBpmValue,
        labelBloodPIValue,
        labelMinMaxBPValue,
        labelMeanBPValue,
        labelRespiratoryValue,
      };
    });

    synchronizeAxisIntervals(
      ...chartList.map((chart) => chart.getDefaultAxisX())
    );

    const seriesList = chartList.map((chart, i) => {
      const channel = channels[i];
      const series = chart
        .addLineSeries({
          dataPattern: {
            pattern: "ProgressiveX",
          },
          automaticColorIndex: Math.max(i - 1, 0),
        })
        .setName(channel.name)
        .setDataCleaning({ minDataPointCount: 1000 });

      if (channel.name === "Pleth") {
        series.setStrokeStyle((stroke: any) => stroke.setFillStyle());
      }
      return series;
    });

    let tSamplePos = window.performance.now();
    let iSampleX = 0;
    const addData = () => {
      const tNow = window.performance.now();
      const seriesNewPoints = seriesList.map((_) => []);
      if (tNow - lastDataReceivedTime.current > 20000) {
        // If no data received, reset everything.
        channels.forEach((channel) => {
          channel.dataSet.length = 0;
        });
        uiList.forEach((ui) => {
          ui.labelEcgHeartRate && ui.labelEcgHeartRate.setText("");
          ui.labelBpmValue && ui.labelBpmValue.setText("");
          ui.labelBpmValue && ui.labelBloodPIValue.setText("");
          ui.labelMinMaxBPValue && ui.labelMinMaxBPValue.setText("");
          ui.labelMeanBPValue && ui.labelMeanBPValue.setText("");
          ui.labelRespiratoryValue && ui.labelRespiratoryValue.setText("");
        });
      }
      while (tNow > tSamplePos) {
        const x = tSamplePos;
        for (let i = 0; i < seriesList.length - 1; i++) {
          const channel = channels[i];
          const dataSet = channel.dataSet;
          const sample = dataSet[iSampleX % dataSet.length];
          // @ts-ignore
          seriesNewPoints[i].push({ x, y: sample });
        }
        tSamplePos += 1000 / SAMPLE_RATE;
        iSampleX += 1;
      }
      seriesList.forEach((series, i) => series.add(seriesNewPoints[i]));
      channelIncomingDataPointsCount += seriesNewPoints[0].length;
      requestAnimationFrame(addData);
    };
    requestAnimationFrame(addData);

    let channelIncomingDataPointsCount = 0;
    let channelIncomingDataPointsLastUpdate = window.performance.now();
    setInterval(() => {
      const tNow = window.performance.now();
      const chDataPointsPerSecond = Math.round(
        (channelIncomingDataPointsCount * 1000) /
          (tNow - channelIncomingDataPointsLastUpdate)
      );

      uiList.forEach((ui, i) => {
        ui.labelSampleRate.setText(`${chDataPointsPerSecond} samples / second`);
      });
      channelIncomingDataPointsCount = 0;
      channelIncomingDataPointsLastUpdate = tNow;
    }, 2000);

    const vitalGraphsContainer = document.getElementById("vitalGraphs");
    vitalGraphsContainer?.replaceChildren(layoutCharts);

    createSocketConnection(uiList, addData);
  };

  function createSocketConnection(uiList, addData) {
    const randomID = generateRandomID(4);
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket?deviceId=${randomID}&patientId=${patientDetails.patient_uhid}`
    );
    socketRef.current = socket;

    socket.onopen = function (event) {
      console.log("WebSocket connection opened", event);
    };

    socket.onmessage = function (event) {
      const message = JSON.parse(event.data);
      updateChartData(message, uiList);
      requestAnimationFrame(addData);
    };

    socket.onerror = function (event) {
      console.log("WebSocket error observed:", event);
    };

    socket.onclose = function (event) {
      console.log("Websocket closure code:", event.code);
      if (event.code !== 1000 && event.code !== 1001) {
        console.log(
          "Websocket closed abnormally. Reconnecting to WebSocket server..."
        );
        createSocketConnection(uiList, addData);
      }
    };
  }

  const updateChartData = (response: any, uiList: any) => {
    lastDataReceivedTime.current = window.performance.now();
    console.log("response", response, new Date());
    let ecgData = response?.ecg?.split("^");
    let ecgHeartRate = response?.ecgHeartRate;
    let bloodPressure = response?.bloodPressure?.split("^");
    let pulseRate = response?.pulseRate?.split("^");
    let pusleRateValue = response?.pulseRateValue;
    let respiratoryRateData = response?.respiratoryGraph?.split("^");
    let systolicBpValue = response?.systolicBpValue;
    let diastolicBpValue = response?.diastolicBpValue;
    let meanBpValue = response?.meanBpValue;
    let spo2 = response?.spo2;
    let respiratoryValue = response?.respiratoryValue;
    let bloodPerforationIndex = response?.bloodPerforationIndex;

    uiList.forEach((ui) => {
      if (ui.labelEcgHeartRate) {
        if (ecgHeartRate && pusleRateValue) {
          ui.labelEcgHeartRate.setText(ecgHeartRate.toString());
        } else if (ecgHeartRate) {
          ui.labelEcgHeartRate.setText(ecgHeartRate.toString());
        } else if (pusleRateValue) {
          ui.labelEcgHeartRate.setText(pusleRateValue.toString());
        }
      }
      if (ui.labelBpmValue) {
        if (spo2) {
          ui.labelBpmValue.setText(spo2?.toString());
        }
        if (bloodPerforationIndex) {
          ui.labelBloodPIValue.setText(
            "     PI: " + bloodPerforationIndex?.toString()
          );
        }
      }
      if (ui.labelMinMaxBPValue) {
        if (systolicBpValue && diastolicBpValue) {
          ui.labelMinMaxBPValue.setText(
            systolicBpValue?.toString() + "/" + diastolicBpValue?.toString()
          );
        }
      }
      if (ui.labelMeanBPValue) {
        if (meanBpValue) {
          ui.labelMeanBPValue.setText(
            "      (" + meanBpValue?.toString() + ")"
          );
        }
      }
      if (ui.labelRespiratoryValue) {
        if (respiratoryValue) {
          ui.labelRespiratoryValue.setText(respiratoryValue?.toString());
        }
      }
    });

    const ecgIndex = channels.findIndex(
      (channel) => channel.type === ChartDataType.ecg
    );
    if (ecgData) {
      ecgData
        ?.filter((item: number) => item < 1000)
        ?.map((item: string) => {
          return channels[ecgIndex].dataSet.push(item);
        });
    }
    const bloodPressureIndex = channels.findIndex(
      (channel) => channel.type === ChartDataType.bloodPressure
    );
    if (bloodPressure) {
      bloodPressure
        ?.filter((item: number) => item < 1000)
        ?.map((item: string) => {
          return channels[bloodPressureIndex].dataSet.push(item);
        });
    }

    const pulseIndex = channels.findIndex(
      (channel) => channel.type === ChartDataType.pulse
    );
    if (pulseRate) {
      pulseRate
        ?.filter((item: number) => item < 1000)
        ?.map((res: string) => {
          return channels[pulseIndex].dataSet.push(res);
        });
    }

    const respiratoryRateIndex = channels.findIndex(
      (channel) => channel.type === ChartDataType.resp
    );
    if (respiratoryRateData) {
      respiratoryRateData
        ?.filter((item: number) => item < 1000)
        ?.map((res: string) => {
          return channels[respiratoryRateIndex].dataSet.push(res);
        });
    }
  };

  useEffect(() => {
    createCharts();
    return () => {
      closeWebSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_uhid]);

  return (
    <div id="patient-chart-container" className="chart-container">
      <div id="vitalGraphs"></div>
    </div>
  );
};

export default PatientVitals;
