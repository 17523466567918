import { useEffect, useState } from "react";
import { iCriticalBedList } from "../interfaces";
import { createApiUrl } from "./helperFunctions";
import { CRITICAL_BEDS_ID } from "./constants";

const useGetCriticalBeds = (location: string) => {
  const [criticalBedList, setCriticalBedList] = useState<iCriticalBedList[]>(
    []
  );

  function fetchCriticalBeds() {
    const payload = {
      intent: "getCriticalBedsByLocation",
      state: { locationId: location },
    };
    fetch(createApiUrl(CRITICAL_BEDS_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => setCriticalBedList(data?.bed))
      .catch((err) =>
        console.error("Error communicating with server. Error code: ", err)
      );
  }

  useEffect(() => {
    fetchCriticalBeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return criticalBedList;
};

export default useGetCriticalBeds;
