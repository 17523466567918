import { MutableRefObject, useRef } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { CustomArrowIcon } from "../../utils/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { ambulanceList, ambulanceText } from "../../utils/constants";
import {
  setCurrentLocation,
  setisAmbulanceClicked,
  setSelectedAmbulanceIndex,
} from "../../redux/actions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import AmbulanceLiveData from "./AmbulanceLiveData/AmbulanceLiveData";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import siren from "../../assets/siren.png";
import "./Ambulance.css";

const Ambulance = () => {
  const currentLocation = useSelector((state: any) => state.currentLocation);
  const onlyLocations = useSelector((state: any) => state.onlyLocations);
  const dispatch = useDispatch();
  const ambulanceIndex = useSelector(
    (state: any) => state.selectedAmbulanceIndex
  );

  const isClicked = useSelector((state: any) => state.isAmbulanceClicked);
  const ambulanceCardRef = useRef() as MutableRefObject<HTMLDivElement>;

  const handleSelectedAmbulance = (index: number) => {
    if (ambulanceIndex !== null) {
      ambulanceCardRef.current.children[ambulanceIndex]?.classList.remove(
        "device-card-selected"
      );
    }
    ambulanceCardRef.current.children[index].classList.add(
      "device-card-selected"
    );

    dispatch(setSelectedAmbulanceIndex(index));
    dispatch(setisAmbulanceClicked(true));
  };

  const handleLocationChange = (e: SelectChangeEvent) => {
    dispatch(setCurrentLocation(e.target.value));
  };

  return (
    <div id="ambulance-details" className="ambulance-container">
      <Grid container columns={16}>
        <Grid item xs={16} sm={7} md={4} lg={3} xl={2}>
          <Select
            id="ambulance-location"
            value={currentLocation}
            onChange={handleLocationChange}
            IconComponent={CustomArrowIcon}
            className="select-location"
          >
            {onlyLocations?.map((item) => (
              <MenuItem id="ambulance-location-item" value={item} key={item}>
                <div id="location-container" className="location-default-item">
                  <FmdGoodOutlinedIcon
                    id="location-icon"
                    style={{ color: "#1b5299" }}
                  />
                  <span id="location-name" className="location-list-item">
                    {item}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={16} sm={9} md={12} lg={13} xl={14}>
          <div
            id="ambulance-lists"
            className="ambulance-section"
            ref={ambulanceCardRef}
          >
            {ambulanceList?.length ? (
              <>
                {ambulanceList?.map((ambulance, index) => {
                  return (
                    <div
                      id="ambulance-card-box"
                      key={ambulance.ambulanceId}
                      className={`ambulance-card ${
                        index === ambulanceIndex
                          ? "ambulance-card-selected"
                          : ""
                      }`}
                      onClick={() => handleSelectedAmbulance(index)}
                    >
                      <div id="ambulance-icon-box" className="ambulance-image">
                        <img
                          alt="ambulance"
                          src={siren}
                          height={30}
                          width={30}
                        />
                      </div>
                      <div id="ambulance-name-box">
                        <Box
                          id="ambulance-name"
                          sx={{ typography: "body2" }}
                          className="ambulance-name"
                        >
                          {ambulance.ambulanceName}
                        </Box>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div id="no-ambulances" className="no-ambulance-available">
                {ambulanceText.noAmbulanceAvailableText}
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      {isClicked ? (
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <AmbulanceLiveData />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
};

export default Ambulance;
