import { styled } from "@mui/material/styles";
import Tab from "@mui/material/Tab";

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab id="styled-tab" {...props} />
))(() => ({
  color: "white",
  "&.Mui-selected": {
    color: "#f1f1f1",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "orange",
  },
}));

export default StyledTab;
