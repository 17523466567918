import {
  useRef,
  useState,
  useEffect,
  FormEvent,
  ChangeEvent,
  SyntheticEvent,
} from "react";
import {
  tests,
  submit,
  medications,
  unitOptions,
  instructions,
  routeOptions,
  durationUnits,
  treatmentsText,
  frequencyOptions,
  medicationsTable,
  labSuggestionsText,
  treatmentAndMedications,
  IPD_PROJECT_ID,
  OPD_PROJECT_ID,
  INTENSIVIST_DOCTOR_ID,
  notInList,
  oneQuantityText,
} from "../../utils/constants";
import {
  action,
  addDays,
  titleCase,
  formatDate,
  createApiUrl,
  calculateFreq,
  getCurrentDate,
  getCurrentDateAndTime,
} from "../../utils/helperFunctions";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { iPanels, FormData, MedicineData } from "../../interfaces";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Backdrop from "@mui/material/Backdrop";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import useGetTestPanels from "../../utils/useGetTestPanels";
import MedicationConfirmationDialog from "./MedicationConfirmationDialog/MedicationConfirmationDialog";
import "./TreatmentAndMedications.css";
dayjs.extend(isoWeek);

const TreatmentAndMedications = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_fhir_id: state.patient_fhir_id,
    bedId: state.bedId,
    bedNumber: state.bedNumber,
    admissionDate: state.admissionDate,
    currentLocation: state.currentLocation,
    locationsList: state.locationsList,
  }));
  const medicationRef = useRef<HTMLDivElement | null>(null);
  const { panelsList, allPanels } = useGetTestPanels();
  const [openList, setOpenList] = useState<boolean>(false);
  const [testsInputQuery, setTestsInputQuery] = useState("");
  const [testsOptions, setTestsOptions] = useState<string[]>([]);
  const [selectedTests, setSelectedTests] = useState<string[]>([]);
  const [drugList, setDrugList] = useState<any[]>([]);
  const [alternativeDisabled, setAlternativeDisabled] =
    useState<boolean>(false);
  const [labSuggestions, setLabSuggestions] = useState<string>("");
  const [selectedDrug, setSelectedDrug] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [visitCreationStatus, setVisitCreationStatus] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [newVisitID, setNewVisitID] = useState<string>("");
  const [medicines, setMedicines] = useState<MedicineData[]>([]);
  const [backdropStatus, setBackdropStatus] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [debounceTimeout, setDebounceTimeout] = useState<any>(null);
  const [doseError, setDoseError] = useState<string>("");
  const [durationError, setDurationError] = useState<string>("");
  const [quantitiesError, setQuantititesError] = useState<string>("");
  const [isInitialRenderForDose, setIsInititalRenderForDose] =
    useState<boolean>(true);
  const [isInitialRenderForDuration, setIsInititalRenderForDuration] =
    useState<boolean>(true);
  const [isInitialRenderForQuantity, setIsInititalRenderForQuantity] =
    useState<boolean>(true);
  const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    drugName: selectedDrug,
    dose: "",
    unit: "",
    frequency: "",
    route: "",
    duration: "",
    radioOptions: [],
    startDate: "",
    endDate: "",
    durationUnits: "",
    quantities: "",
    quantityUnits: "",
    sos: false,
    instructions: "",
    additional: "",
  });
  const isInitialRenderForAPIs = useRef(true);
  const isInitialRenderMedicinesPayload = useRef(true);
  const drugCloseButton = medicationRef.current?.querySelector(
    'button[aria-label="Clear"]'
  ) as HTMLButtonElement | null;

  //Payload for triaged table
  const [panels, setPanels] = useState<iPanels[]>([]);
  const [medicinesPayload, setMedicinesPayload] = useState<any[]>([]);
  const locationIdForPayload = patientDetails.locationsList?.find(
    (item) => item?.location === patientDetails.currentLocation
  )?.id;

  const handleTestsInputChange = (event, value) => {
    setTestsInputQuery(value);
    if (value.length >= 2) {
      setTestsOptions(testsSearchOptions(value));
    } else {
      setTestsOptions([]);
    }
  };

  const testsSearchOptions = (query) => {
    return panelsList?.filter((option) =>
      option?.toLowerCase().includes(query.toLowerCase())
    );
  };

  const handleTestsChange = (event, newValue) => {
    setSelectedTests(newValue);
  };

  function fetchDrugsList(value: string) {
    const payload = {
      intent: "getDrugsByName",
      state: {
        name: value,
        location: patientDetails.locationsList?.find(
          (item) => item?.location === patientDetails.currentLocation
        )?.id,
      },
    };
    fetch(createApiUrl(OPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.length) {
          setDrugList(
            data?.map((drug) => ({
              label: (
                <div id="drug-list">
                  <div id="drug-name">{drug?.name}</div>
                  <div id="drug-quantity">Qty-{drug?.quantity}</div>
                  <div id="drug-composition" className="druglist-composition">
                    Composition:&nbsp;{drug?.composition}
                  </div>
                </div>
              ),
              value: drug?.name,
            }))
          );
        } else {
          setDrugList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setDrugList([
          {
            label: (
              <div>
                <div>{value}</div>
                <div>{oneQuantityText}</div>
                <div className="not-in-list">{notInList}</div>
              </div>
            ),
            value: value,
          },
        ]);
      });
  }

  const handleDrugChange = (value: string) => {
    setSelectedDrug(value);
    setQuery(value);
  };

  const handleSelectChange = (selectedOption: any) => {
    setSelectedDrug(selectedOption ? selectedOption?.value : "");
    setFormData({
      ...formData,
      drugName: selectedOption ? selectedOption.value : "",
    });
  };

  const handleAlternateDrug = (name: any) => {
    try {
      const payload = {
        intent: "getDrugsByComposition",
        state: {
          name: name,
          location: patientDetails.locationsList?.find(
            (item) => item?.location === patientDetails.currentLocation
          )?.id,
        },
      };
      fetch(createApiUrl(OPD_PROJECT_ID), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
          "Content-Type": "application/json",
          version: "WORKING",
        },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.length > 0) {
            setDrugList(
              data?.map((drug) => ({
                label: (
                  <div id="alternate-drug-list">
                    <div id="alternate-drug-name">{drug?.name}</div>
                    <div id="alternate-drug-quantity">Qty-{drug?.quantity}</div>
                    <div
                      id="alternate-drug-composition"
                      className="druglist-composition"
                    >
                      Composition:&nbsp;{drug?.composition}
                    </div>
                  </div>
                ),
                value: drug?.name,
              }))
            );
            setAlternativeDisabled(true);
            setOpenList(true);
            if (drugCloseButton) {
              drugCloseButton.click();
            }
          } else {
            setDrugList([]);
          }
        })
        .catch((err) => {
          console.error("Error communicating with server. Error code: ", err);
        });
    } catch (error) {
      console.error("Error fetching drugs:", error);
      setDrugList([]);
    }
  };

  const handleNameChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => {
      const newFormData = {
        ...prevFormData,
        [name]: value,
      };

      if (name === "unit") {
        newFormData.quantityUnits = value;
      }

      if (name === "duration" || name === "frequency" || name === "dose") {
        const frequencies = calculateFreq(
          newFormData.frequency,
          parseInt(newFormData.dose),
          newFormData.duration,
          newFormData?.radioOptions?.length
        );
        newFormData.durationUnits = frequencies?.unitsOne ?? "";
        newFormData.quantities =
          newFormData?.unit === "tablet" || newFormData?.unit === "capsule"
            ? frequencies?.quantities ?? ""
            : "";
        if (name === "frequency") {
          Object.assign(newFormData, {
            radioOptions: [],
            startDate: "",
            endDate: "",
            duration: "",
          });
        }
      }

      if (name === "startDate" && value) {
        const days =
          newFormData.frequency === "On alternate days"
            ? Number(newFormData?.duration) * 2
            : newFormData.durationUnits === "week"
            ? Number(newFormData?.duration) * 7
            : newFormData.durationUnits === "day"
            ? Number(newFormData?.duration)
            : newFormData.durationUnits === "month"
            ? Number(newFormData?.duration) * 30
            : newFormData.durationUnits === "2 week"
            ? Number(newFormData?.duration) * 17
            : newFormData.durationUnits === "month"
            ? Number(newFormData?.duration) * 30
            : 1;

        const parsedDate = new Date(value);
        const newDate = addDays(parsedDate, days);
        const formatted = formatDate(newDate);
        newFormData.endDate = formatted;
      }

      // if (name === "frequency") {
      //   newFormData.radioOptions = [];
      //   if (newFormData?.durationUnits === "hour") {
      //     newFormData.radioOptions = [
      //       {
      //         name: "starting from",
      //         value: `starting from ${getTime()}`,
      //       },
      //     ];
      //   }
      // }
      return newFormData;
    });
  };

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name, checked } = e.target;
    const newstate = formData;

    const updatedOptions = checked
      ? [...newstate.radioOptions, { name, value }]
      : newstate.radioOptions.filter(
          (option) => !(option.name === name && option.value === value)
        );

    if (newstate?.frequency === "Once a day" && updatedOptions.length > 1) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as ONCE A DAY");
    } else if (
      newstate?.frequency === "Twice a day" &&
      updatedOptions.length > 2
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as TWICE A DAY");
    } else if (
      newstate?.frequency === "Thrice a day" &&
      updatedOptions.length > 3
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as THRICE A DAY");
    } else if (
      newstate?.frequency === "Four times a day" &&
      updatedOptions.length > 4
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as FOUR TIMES A DAY");
    } else if (
      newstate?.frequency === "Five times a day" &&
      updatedOptions.length > 5
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as FIVE TIMES A DAY");
    } else if (
      newstate?.frequency === "Once a week" &&
      updatedOptions.length > 1
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Once A Week");
    } else if (
      newstate?.frequency === "Twice a week" &&
      updatedOptions.length > 2
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Twice A Week");
    } else if (
      newstate?.frequency === "Thrice a week" &&
      updatedOptions.length > 3
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Three Days A Week");
    } else if (
      newstate?.frequency === "Four days a week" &&
      updatedOptions.length > 4
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Four days A Week");
    } else if (
      newstate?.frequency === "Five days a week" &&
      updatedOptions.length > 5
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Five days A Week");
    } else if (
      newstate?.frequency === "Six days a week" &&
      updatedOptions.length > 6
    ) {
      setOpenSnackbar(true);
      setMessage("Frequency selected as Six days A Week");
    } else {
      setFormData({ ...newstate, radioOptions: updatedOptions });
    }
  };

  const handleSosChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: checked,
      instructions: checked ? prevFormData.instructions : "",
      additional: checked ? prevFormData.additional : "",
    }));
  };

  const handleTimeChange = (name: string, value: Dayjs | null) => {
    setFormData((prevState) => ({
      ...prevState,
      radioOptions: [
        {
          ...prevState?.radioOptions[0],
          [name]: dayjs(value).format("HH:mm:ssZ"),
        },
      ],
    }));
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const addMedicine = () => {
    if (
      formData?.frequency === "On alternate days" &&
      formData.radioOptions.length <= 0
    ) {
      setOpenSnackbar(true);
      setMessage("Please select alternative days");
      return;
    }
    if (
      formData?.frequency === "Once a day" &&
      formData.radioOptions.length < 1
    ) {
      setOpenSnackbar(true);
      setMessage("Please select atleast one frequency");
      return;
    } else if (
      formData?.frequency === "Twice a day" &&
      formData.radioOptions.length < 2
    ) {
      setOpenSnackbar(true);
      setMessage("Please select two frequencies");
      return;
    } else if (
      formData?.frequency === "Thrice a day" &&
      formData.radioOptions.length < 3
    ) {
      setOpenSnackbar(true);
      setMessage("Please select three frequencies");
      return;
    } else if (
      formData?.frequency === "Four times a day" &&
      formData.radioOptions.length < 4
    ) {
      setOpenSnackbar(true);
      setMessage("Please select four frequencies");
      return;
    } else if (
      formData?.frequency === "Five times a day" &&
      formData.radioOptions.length < 5
    ) {
      setOpenSnackbar(true);
      setMessage("Please select five frequencies");
      return;
    } else if (
      formData?.frequency === "Once a week" &&
      formData.radioOptions.length < 1
    ) {
      setOpenSnackbar(true);
      setMessage("Please select one day");
      return;
    } else if (
      formData?.frequency === "Twice a week" &&
      formData.radioOptions.length < 2
    ) {
      setOpenSnackbar(true);
      setMessage("Please select two days");
      return;
    } else if (
      formData?.frequency === "Thrice a week" &&
      formData.radioOptions.length < 3
    ) {
      setOpenSnackbar(true);
      setMessage("Please select three days");
      return;
    } else if (
      formData?.frequency === "Four days a week" &&
      formData.radioOptions.length < 4
    ) {
      setOpenSnackbar(true);
      setMessage("Please select four days");
      return;
    } else if (
      formData?.frequency === "Five days a week" &&
      formData.radioOptions.length < 5
    ) {
      setOpenSnackbar(true);
      setMessage("Please select five days");
      return;
    } else if (
      formData?.frequency === "Six days a week" &&
      formData.radioOptions.length < 6
    ) {
      setOpenSnackbar(true);
      setMessage("Please select six days");
      return;
    }

    setMedicines([...medicines, formData]);
    setFormData({
      drugName: "",
      dose: "",
      unit: "",
      frequency: "",
      route: "",
      duration: "",
      radioOptions: [],
      startDate: "",
      endDate: "",
      durationUnits: "",
      quantities: "",
      quantityUnits: "",
      sos: false,
      instructions: "",
      additional: "",
    });
    setSelectedDrug("");
    if (drugCloseButton) {
      drugCloseButton.click();
    }
  };

  const getWeekCount = (startDate: string, endDate: string): number => {
    const startMoment = dayjs(startDate);
    const endMoment = dayjs(endDate);
    const weeks = endMoment.diff(startMoment, "week");
    return weeks + 1;
  };

  const handleDurationForWeeks = (
    frequency: string,
    datesLength: number,
    startDate: string,
    endDate: string
  ) => {
    switch (frequency) {
      case "On alternate days":
        return datesLength.toString();
      case "Once a week":
        return datesLength.toString();
      case "Twice a week":
        return getWeekCount(startDate, endDate).toString();
      case "Thrice a week":
        return getWeekCount(startDate, endDate).toString();
      case "Four days a week":
        return getWeekCount(startDate, endDate).toString();
      case "Five days a week":
        return getWeekCount(startDate, endDate).toString();
      case "Six days a week":
        return getWeekCount(startDate, endDate).toString();
      default:
        return "";
    }
  };

  const sortDatesAndSetRange = (dates: string[], frequency: string) => {
    if (!dates || dates.length === 0) {
      setOpenSnackbar(true);
      setMessage("No dates provided or dates array is empty");
      return;
    }
    const sortedDates = dates.sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const startDate = sortedDates[0];
    const endDate = sortedDates[sortedDates.length - 1];

    setFormData((prevState) => ({
      ...prevState,
      startDate: startDate,
      endDate: endDate,
      duration: handleDurationForWeeks(
        frequency,
        dates?.length,
        startDate,
        endDate
      ),
    }));
  };

  const handleDisableDates = (
    date: any,
    selectedDates: any,
    frequency: string
  ) => {
    switch (frequency) {
      case "On alternate days":
        return disableAlternativeDays(date, selectedDates);
      case "Once a week":
        return disableWeeks(date, selectedDates, 1);
      case "Twice a week":
        return disableWeeks(date, selectedDates, 2);
      case "Thrice a week":
        return disableWeeks(date, selectedDates, 3);
      case "Four days a week":
        return disableWeeks(date, selectedDates, 4);
      case "Five days a week":
        return disableWeeks(date, selectedDates, 5);
      case "Six days a week":
        return disableWeeks(date, selectedDates, 6);
      default:
        break;
    }
  };

  const disableAlternativeDays = (date: any, selectedDates: any) => {
    const isSelected = selectedDates.some(
      (selectedDate: any) =>
        dayjs(date).format("YYYY-MM-DD") ===
        dayjs(selectedDate).format("YYYY-MM-DD")
    );
    if (isSelected) {
      return false;
    }
    return selectedDates.some((selectedDate: any) => {
      const dayDifference = dayjs(date)?.diff(dayjs(selectedDate), "day");
      return dayDifference % 2 !== 0;
    });
  };

  const disableWeeks = (
    date: any,
    selectedDates: any,
    disableFrequency: number
  ) => {
    const weekOfYear = dayjs(date).isoWeek();
    const isSelected = selectedDates?.some(
      (selectedDate: any) =>
        dayjs(date).format("YYYY-MM-DD") ===
        dayjs(selectedDate).format("YYYY-MM-DD")
    );
    if (isSelected) {
      return false;
    }
    const selectedInSameWeek = selectedDates?.filter(
      (selectedDate: any) => dayjs(selectedDate).isoWeek() === weekOfYear
    ).length;

    return selectedInSameWeek >= disableFrequency;
  };

  const renderTimes = (startTime: Dayjs | null, disable: boolean) => {
    return (
      <div id="times-container" className="drug-flexRow">
        <div className="drug-flexRows gap7">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              disabled={disable}
              value={startTime}
              onChange={(newValue: Dayjs | null) =>
                handleTimeChange("startTime", newValue)
              }
              className="time-picker-medications"
              slotProps={{
                textField: { placeholder: "Start Time" },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    );
  };

  const removeMedicine = (index: number) => {
    setMedicines((prevMedicines) =>
      prevMedicines.filter((_, i) => i !== index)
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitDialogOpen(true);
  };

  const handleCloseSubmitDialog = () => {
    setSubmitDialogOpen(false);
  };

  const submitVisit = (e: FormEvent) => {
    e.preventDefault();
    handleCloseSubmitDialog();

    const payloadForVisitCreation = {
      intent: "createVisitForCC",
      state: {
        patientId: patientDetails.patient_fhir_id,
        encounterStatus: "arrived",
        startTime: getCurrentDateAndTime(),
        doctorId: INTENSIVIST_DOCTOR_ID,
        locationId: locationIdForPayload,
        bedId: patientDetails.bedId,
        bedNo: patientDetails.bedNumber,
        admittedDateAndTime: patientDetails.admissionDate,
      },
    };

    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForVisitCreation),
    })
      .then((res) => res.json())
      .then((data) => {
        setNewVisitID(data?.data?.id);
        setBackdropStatus(true);
      });
  };

  const sosCheck = () => {
    if (Number(formData?.dose) <= 0) {
      return true;
    }
    if (Number(formData?.duration) <= 0) {
      return true;
    }
    if (Number(formData?.quantities) <= 0) {
      return true;
    } else if (formData.sos) {
      if (formData.instructions.length > 0 && formData.additional.length > 0) {
        return false;
      } else {
        return true;
      }
    }
    if (formData.startDate.length === 0) {
      return true;
    }
    if (formData.endDate.length === 0) {
      return true;
    }
  };

  useEffect(() => {
    const allSelectedTests = allPanels?.filter((test) =>
      selectedTests?.includes(test?.name)
    );
    const triagedTestsPayload = allSelectedTests?.map((item) => {
      return {
        id: item?.id,
        name: item?.name,
        sampleId: item?.sample?.id,
        lab_tests: item?.lab_tests?.map((test) => {
          return {
            id: test?.id,
            name: test?.name,
            panelId: item?.id,
            sampleId: item?.sample?.id,
            panelName: test?.name,
            sampleName: item?.sample?.name,
            description: test?.description,
          };
        }),
        sampleName: item?.sample?.name,
        description: item?.description,
      };
    });
    setPanels(triagedTestsPayload);
  }, [selectedTests, allPanels]);

  useEffect(() => {
    if (isInitialRenderMedicinesPayload.current) {
      isInitialRenderMedicinesPayload.current = false;
      return;
    }
    setMedicinesPayload(
      medicines?.map((item) => {
        return {
          additional_instructions: item?.additional,
          drug_name: item?.drugName,
          dose_units: item?.quantityUnits,
          quantity_units: item?.quantityUnits,
          frequency: item?.frequency,
          instructions: item?.instructions,
          route: item?.route,
          sos: item?.sos,
          start_date: item?.startDate,
          total_quantity: item?.quantities,
          duration: item?.duration,
          visit_id: newVisitID,
          duration_units: item?.durationUnits,
          dose: item?.dose,
          next_visit_date: item?.endDate,
          intervals: item?.radioOptions,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVisitID]);

  useEffect(() => {
    if (isInitialRenderForAPIs.current) {
      isInitialRenderForAPIs.current = false;
      return;
    }

    const addToStaging = setTimeout(() => {
      const payloadForStagingTable = {
        intent: "addToStaging",
        state: {
          visit_id: newVisitID,
          lab_tests: [],
          medicine_suggestion: medicinesPayload,
          panels: panels,
          patient_id: patientDetails.patient_fhir_id,
          requested_by: "Intensivist Command Centre",
          source: patientDetails.currentLocation,
          treatment_plan: labSuggestions,
          user_id: sessionStorage.getItem("user-id"),
        },
      };

      fetch(createApiUrl(IPD_PROJECT_ID), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
          "Content-Type": "application/json",
          version: "WORKING",
        },
        body: JSON.stringify(payloadForStagingTable),
      });
    }, 2000);

    const createTreatmentPlan = setTimeout(() => {
      const payloadForCreateLabOrderAndMedication = {
        intent: "createTreatmentPlanForCC",
        state: {
          visit_id: newVisitID,
          data_status: "PROCESSED",
        },
      };
      fetch(createApiUrl(IPD_PROJECT_ID), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
          "Content-Type": "application/json",
          version: "WORKING",
        },
        body: JSON.stringify(payloadForCreateLabOrderAndMedication),
      });
    }, 4000);

    const updateStatusInCommandCenter = setTimeout(() => {
      const payloadForApproval = {
        intent: "updateStatusInCommandCentre",
        state: {
          encounterId: newVisitID,
          encounterStatus: "finished",
        },
      };

      fetch(createApiUrl(IPD_PROJECT_ID), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
          "Content-Type": "application/json",
          version: "WORKING",
        },
        body: JSON.stringify(payloadForApproval),
      })
        .then((response) => {
          if (response.status !== 200) {
            setOpenSnackbar(true);
            setMessage("Unable to create visit. Please try again.");
            setBackdropStatus(false);
            throw new Error("Server error");
          }
          return response.json();
        })
        .then((_) => {
          setOpenSnackbar(true);
          setVisitCreationStatus(true);
          setMessage("VISIT CREATED SUCCESSFULLY.");
          setBackdropStatus(false);
          setFormData({
            drugName: "",
            dose: "",
            unit: "",
            frequency: "",
            route: "",
            duration: "",
            radioOptions: [],
            startDate: "",
            endDate: "",
            durationUnits: "",
            quantities: "",
            quantityUnits: "",
            sos: false,
            instructions: "",
            additional: "",
          });
          setLabSuggestions("");
          setSelectedTests([]);
          setMedicines([]);
          setSelectedDrug("");
          if (drugCloseButton) {
            drugCloseButton.click();
          }
        });
      setTimeout(() => {
        setVisitCreationStatus(false);
      }, 6000);
    }, 6000);

    return () => {
      clearTimeout(addToStaging);
      clearTimeout(createTreatmentPlan);
      clearTimeout(updateStatusInCommandCenter);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicinesPayload]);

  useEffect(() => {
    if (isInitialRenderForDose) {
      setIsInititalRenderForDose(false);
      return;
    }
    const positiveIntegerRegex = /^[1-9]\d*$/;
    if (!positiveIntegerRegex.test(formData.dose)) {
      setDoseError("Dose value must be greater than zero.");
      return;
    } else {
      setDoseError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.dose]);

  useEffect(() => {
    if (isInitialRenderForDuration) {
      setIsInititalRenderForDuration(false);
      return;
    }
    const positiveIntegerRegex = /^[1-9]\d*$/;
    if (!positiveIntegerRegex.test(formData.duration)) {
      setDurationError("Duration value must be greater than zero.");
      return;
    } else {
      setDurationError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.duration]);

  useEffect(() => {
    if (isInitialRenderForQuantity) {
      setIsInititalRenderForQuantity(false);
      return;
    }
    const positiveIntegerRegex = /^[1-9]\d*$/;
    if (!positiveIntegerRegex.test(formData.quantities)) {
      setQuantititesError("Quantity value must be greater than zero.");
      return;
    } else {
      setQuantititesError("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.quantities]);

  useEffect(() => {
    if (query) {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }

      const timeout = setTimeout(() => {
        fetchDrugsList(query);
      }, 500);

      setDebounceTimeout(timeout);
    }

    return () => {
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return (
    <div
      id="treatment-and-medications-container"
      className="treatment-and-medications"
    >
      <div id="header" className="action-header">
        {treatmentAndMedications}
      </div>
      <Box id="tests-text" className="tests-and-medications">
        {tests}:
      </Box>
      <Autocomplete
        id="select-tests"
        multiple
        options={testsOptions}
        value={selectedTests}
        onChange={handleTestsChange}
        inputValue={testsInputQuery}
        onInputChange={handleTestsInputChange}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField {...params} placeholder="Search Tests" />
        )}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              id="select-test-checkbox"
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        disableCloseOnSelect
        open={testsInputQuery?.length >= 2}
      />
      <Box id="medication-text" className="tests-and-medications">
        {medications}:
      </Box>
      <div id="medications-table-container" className="medications-table">
        <TableContainer id="add-medicaitons-table" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="medicine-name-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.medicineName}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="dose-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.dose}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="left">
                  <Typography
                    id="unit-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.unit}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="route-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.route}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="frequency-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.frequency}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="interval-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.interval}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="duration-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.duration}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="total-quantity-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.totalQty}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="start-date-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.startDate}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="end-date-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.endDate}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="sos-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.sos}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="action-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationsTable.action}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {medicines?.length > 0
                ? medicines?.map((row: any, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell id="medicine-name-value">
                          {row?.drugName}
                        </TableCell>
                        <TableCell id="dose-value">{row?.dose}</TableCell>
                        <TableCell id="unit-value">
                          {titleCase(row?.unit)}
                        </TableCell>
                        <TableCell id="route-value">
                          {titleCase(row?.route)}
                        </TableCell>
                        <TableCell id="frequency-value">
                          {row?.frequency}
                        </TableCell>
                        <TableCell id="interval-value">
                          <div
                            id="intervals-container"
                            className="select-interval"
                          >
                            {row?.frequency &&
                              (row?.frequency === "Once a day" ||
                                row?.frequency === "Immediately" ||
                                row?.frequency === "Twice a day" ||
                                row?.frequency === "Thrice a day" ||
                                row?.frequency === "Four times a day" ||
                                row?.frequency === "Five times a day") && (
                                <>
                                  <div
                                    id="breakfast-checkboxes-box"
                                    className="drug-flexColumn"
                                  >
                                    <label id="breakfast-label">
                                      {treatmentsText.breakfast}
                                    </label>
                                    <div
                                      id="breakfast-checkboxes"
                                      className="drug-flexRow"
                                    >
                                      <input
                                        id="before-breakfast"
                                        type="checkbox"
                                        name="breakfast"
                                        value="bb"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "breakfast" &&
                                            option.value === "bb"
                                        )}
                                      />
                                      <input
                                        id="after-breakfast"
                                        type="checkbox"
                                        name="breakfast"
                                        value="ab"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "breakfast" &&
                                            option.value === "ab"
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    id="lunch-checkboxes-box"
                                    className="drug-flexColumn"
                                  >
                                    <label id="lunch-label">
                                      {treatmentsText.lunch}
                                    </label>
                                    <div
                                      id="lunch-checkboxes"
                                      className="drug-flexRow"
                                    >
                                      <input
                                        id="before-lunch-checkbox"
                                        type="checkbox"
                                        name="before lunch"
                                        value="bl"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "before lunch" &&
                                            option.value === "bl"
                                        )}
                                      />
                                      <input
                                        id="after-lunch"
                                        type="checkbox"
                                        name="after lunch"
                                        value="al"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "after lunch" &&
                                            option.value === "al"
                                        )}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    id="dinner-checkboxes-box"
                                    className="drug-flexColumn"
                                  >
                                    <label id="dinner-text">
                                      {treatmentsText.dinner}
                                    </label>
                                    <div
                                      id="dinner-checkboxes"
                                      className="drug-flexRow"
                                    >
                                      <input
                                        id="before-dinner"
                                        type="checkbox"
                                        name="before dinner"
                                        value="bd"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "before dinner" &&
                                            option.value === "bd"
                                        )}
                                      />
                                      <input
                                        id="after-dinner"
                                        type="checkbox"
                                        name="after dinner"
                                        value="ad"
                                        readOnly
                                        checked={row?.radioOptions.some(
                                          (option) =>
                                            option.name === "after dinner" &&
                                            option.value === "ad"
                                        )}
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            {row.frequency &&
                              (row?.frequency === "On alternate days" ||
                                row?.frequency === "Once a week" ||
                                row?.frequency === "Twice a week" ||
                                row?.frequency === "Thrice a week" ||
                                row?.frequency === "Four days a week" ||
                                row?.frequency === "Five days a week" ||
                                row?.frequency === "Six days a week") && (
                                <div
                                  id="multi-date-picker"
                                  className="multi-date-picker-container"
                                >
                                  <DatePicker
                                    disabled
                                    key={1}
                                    multiple
                                    format="DD/MM/YYYY"
                                    plugins={[<DatePanel key={2} />]}
                                    placeholder="Select Dates"
                                    containerClassName="custom-container-multi-date-picker"
                                    value={row?.radioOptions?.map(
                                      (dateString) =>
                                        dayjs(dateString, "YYYY-MM-DD").toDate()
                                    )}
                                  />
                                </div>
                              )}
                            {row?.frequency &&
                              row?.durationUnits === "hour" && (
                                <div
                                  id="starting-from-box"
                                  className="drug-flexRow"
                                >
                                  <div
                                    id="starting-from-value"
                                    className="drug-flexColumn"
                                  >
                                    {row?.radioOptions?.[0]?.value}
                                  </div>
                                </div>
                              )}
                            {row?.frequency &&
                              (row?.frequency === "Every Hour" ||
                                row?.frequency === "Every 2 hours" ||
                                row?.frequency === "Every 3 hours" ||
                                row?.frequency === "Every 4 hours" ||
                                row?.frequency === "Every 6 hours" ||
                                row?.frequency === "Every 8 hours" ||
                                row?.frequency === "Every 12 hours") &&
                              renderTimes(
                                dayjs(
                                  row?.radioOptions[0]?.startTime,
                                  "HH:mm:ssZ"
                                ) || null,
                                true
                              )}
                          </div>
                        </TableCell>
                        <TableCell id="duration-units-value">
                          {row?.duration}&nbsp;{row?.durationUnits}(s)
                        </TableCell>
                        <TableCell id="quantities-value">
                          {row?.quantities}
                        </TableCell>
                        <TableCell id="start-date-value">
                          {row?.startDate}
                        </TableCell>
                        <TableCell id="end-date-value">
                          {row?.endDate}
                        </TableCell>
                        <TableCell id="sos-value">
                          {row?.sos ? "Yes" : "No"}&nbsp;{row?.instructions}
                          &nbsp;
                          {row?.additional}
                        </TableCell>
                        <TableCell>
                          <Button
                            id="remove-medication"
                            variant="contained"
                            color="error"
                            onClick={() => removeMedicine(index)}
                          >
                            {treatmentsText.remove}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              <TableRow>
                <TableCell>
                  <Autocomplete
                    id="medication-input"
                    ref={medicationRef}
                    options={drugList}
                    open={openList}
                    onOpen={() => setOpenList(true)}
                    onClose={() => setOpenList(false)}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    getOptionLabel={(option) => option?.value ?? selectedDrug}
                    onChange={(_, newValue) => {
                      if (!newValue && selectedDrug) {
                        handleSelectChange({ value: selectedDrug });
                      } else {
                        handleSelectChange(newValue);
                      }
                    }}
                    noOptionsText={
                      selectedDrug?.length === 0
                        ? "Enter Some Text"
                        : "No Options"
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="search-drug"
                        size="small"
                        placeholder="Select or search drug"
                        value={selectedDrug}
                        onChange={(e) => handleDrugChange(e.target.value)}
                      />
                    )}
                    renderOption={(props, option) => (
                      <Box
                        {...props}
                        id="drug-option"
                        component="li"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box sx={{ typography: "body2" }}>{option?.label}</Box>
                        <Box display="flex">
                          <Button
                            id="order-button"
                            size="small"
                            onClick={() => handleSelectChange(option)}
                          >
                            {medicationsTable.order}
                          </Button>
                          <Button
                            id="alternate-button"
                            size="small"
                            disabled={alternativeDisabled}
                            onClick={() => handleAlternateDrug(option?.value)}
                          >
                            {medicationsTable.searchAlternative}
                          </Button>
                        </Box>
                      </Box>
                    )}
                    sx={{
                      "& .MuiAutocomplete-input": {
                        fontSize: "12px",
                      },
                      "& .MuiAutocomplete-listbox .MuiAutocomplete-option": {
                        fontSize: "12px",
                      },
                      width: 300,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <input
                    id="dose-input"
                    required
                    name="dose"
                    type="number"
                    className="select-dose"
                    placeholder="Select dose"
                    value={formData.dose}
                    onChange={handleNameChange}
                  />
                  {doseError && doseError.length > 0 && (
                    <Typography id="dose-error" variant="caption" color="error">
                      {doseError}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <select
                    id="unit-input"
                    name="unit"
                    value={formData.unit}
                    className="select-unit"
                    onChange={handleNameChange}
                  >
                    <option id="select-input" value="">
                      {treatmentsText.select}
                    </option>
                    {unitOptions.map((op: any) => (
                      <option
                        id="unit-content"
                        className="option-content"
                        key={op.value}
                        value={op.value}
                      >
                        {op.name}
                      </option>
                    ))}
                  </select>
                </TableCell>
                <TableCell>
                  <select
                    id="route-input"
                    name="route"
                    className="select-route"
                    value={formData.route}
                    onChange={handleNameChange}
                  >
                    <option id="select-route" value="">
                      {treatmentsText.select}
                    </option>
                    {routeOptions.map((op: any) => (
                      <option
                        id="select-route-menuitem"
                        key={op.value}
                        value={op.value}
                      >
                        {op.name}
                      </option>
                    ))}
                  </select>
                </TableCell>
                <TableCell>
                  <select
                    id="frequency-input"
                    name="frequency"
                    value={formData.frequency}
                    onChange={handleNameChange}
                    className="select-frequency"
                  >
                    <option id="select-frequency" value="">
                      {treatmentsText.select}
                    </option>
                    {frequencyOptions.map((op: any) => (
                      <option
                        id="select-frequency-menuitem"
                        key={op.value}
                        value={op.value}
                      >
                        {op.name}
                      </option>
                    ))}
                  </select>
                </TableCell>
                <TableCell>
                  <div id="select-interval-input" className="select-interval">
                    {formData.frequency === "" && (
                      <div id="no-options-found">
                        <span>{treatmentsText.noOptionsFound}</span>
                      </div>
                    )}
                    {formData.frequency === "Once a day" ||
                    formData.frequency === "Twice a day" ||
                    formData.frequency === "Thrice a day" ||
                    formData.frequency === "Five times a day" ||
                    formData.frequency === "Four times a day" ? (
                      <>
                        <div id="breakfast-box" className="drug-flexColumn">
                          <label id="breakfast-input-label">
                            {treatmentsText.breakfast}
                          </label>
                          <div
                            id="breakfast-input-box"
                            className="drug-flexRow"
                          >
                            <input
                              id="before-breakfast-input"
                              type="checkbox"
                              name="breakfast"
                              value="bb"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "breakfast" &&
                                  option.value === "bb"
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              id="after-breakfast-input"
                              type="checkbox"
                              name="breakfast"
                              value="ab"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "breakfast" &&
                                  option.value === "ab"
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>
                        <div id="lunch-box" className="drug-flexColumn">
                          <label id="lunch-input-label">
                            {treatmentsText.lunch}
                          </label>
                          <div id="lunch-input-box" className="drug-flexRow">
                            <input
                              id="before-lunch-input"
                              type="checkbox"
                              name="before lunch"
                              value="bl"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "before lunch" &&
                                  option.value === "bl"
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              id="after-lunch-input"
                              type="checkbox"
                              name="after lunch"
                              value="al"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "after lunch" &&
                                  option.value === "al"
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>
                        <div id="dinner-box" className="drug-flexColumn">
                          <label id="dinner-input-label">
                            {treatmentsText.dinner}
                          </label>
                          <div id="dinner-input-box" className="drug-flexRow">
                            <input
                              id="before-dinner-input"
                              type="checkbox"
                              name="before dinner"
                              value="bd"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "before dinner" &&
                                  option.value === "bd"
                              )}
                              onChange={handleRadioChange}
                            />
                            <input
                              id="after-dinner-input"
                              type="checkbox"
                              name="after dinner"
                              value="ad"
                              checked={formData.radioOptions.some(
                                (option) =>
                                  option.name === "after dinner" &&
                                  option.value === "ad"
                              )}
                              onChange={handleRadioChange}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {(formData.frequency === "On alternate days" ||
                      formData.frequency === "Once a week" ||
                      formData.frequency === "Twice a week" ||
                      formData.frequency === "Thrice a week" ||
                      formData.frequency === "Four days a week" ||
                      formData.frequency === "Five days a week" ||
                      formData.frequency === "Six days a week") && (
                      <div
                        id="select-multiple-dates"
                        className="multi-date-picker-container"
                      >
                        <DatePicker
                          key={1}
                          multiple
                          format="YYYY-MM-DD"
                          plugins={[<DatePanel key={2} />]}
                          placeholder="Select Dates"
                          containerClassName="custom-container-multi-date-picker"
                          value={formData?.radioOptions}
                          onChange={(values) => {
                            setFormData((prevState) => ({
                              ...prevState,
                              radioOptions: values?.map((date) =>
                                date.format("YYYY-MM-DD")
                              ),
                              quantities:
                                formData?.unit === "tablet" ||
                                formData?.unit === "capsule"
                                  ? (
                                      parseInt(prevState?.dose) * values?.length
                                    ).toString()
                                  : "",
                            }));
                            sortDatesAndSetRange(
                              values?.map((date) => date.format("YYYY-MM-DD")),
                              formData?.frequency
                            );
                          }}
                          mapDays={({ date }) => {
                            const isDisabled = handleDisableDates(
                              date,
                              formData?.radioOptions,
                              formData?.frequency
                            );
                            return {
                              disabled: isDisabled,
                            };
                          }}
                        />
                      </div>
                    )}
                    {(formData.frequency === "Every Hour" ||
                      formData.frequency === "Every 2 hours" ||
                      formData.frequency === "Every 3 hours" ||
                      formData.frequency === "Every 4 hours" ||
                      formData.frequency === "Every 6 hours" ||
                      formData.frequency === "Every 8 hours" ||
                      formData.frequency === "Every 12 hours") &&
                      renderTimes(
                        formData?.radioOptions[0]?.startTime
                          ? dayjs(
                              formData?.radioOptions[0]?.startTime,
                              "HH:mm:ssZ"
                            )
                          : null,
                        false
                      )}
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    id="duration-and-units-container"
                    className="duration-and-units"
                  >
                    <input
                      required
                      id="duration-input"
                      name="duration"
                      type="number"
                      className="duration"
                      placeholder="Duration"
                      value={formData.duration}
                      onChange={handleNameChange}
                    />
                    <select
                      id="duration-units-input"
                      name="durationUnits"
                      value={formData.durationUnits}
                      onChange={handleNameChange}
                    >
                      <option id="select-duration-units" value="">
                        {treatmentsText.select}
                      </option>
                      {durationUnits.map((op: any) => (
                        <option
                          id="duration-units-value"
                          key={op.value}
                          value={op.value}
                        >
                          {op.name}
                        </option>
                      ))}
                    </select>
                    {durationError && durationError.length > 0 && (
                      <Typography
                        id="duration-error"
                        variant="caption"
                        color="error"
                      >
                        {durationError}
                      </Typography>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <input
                    required
                    id="quantities-input"
                    name="quantities"
                    type="number"
                    className="quantities"
                    placeholder="Quantities"
                    value={formData.quantities}
                    onChange={handleNameChange}
                  />
                  {quantitiesError && quantitiesError.length > 0 && (
                    <Typography
                      id="quantities-error"
                      variant="caption"
                      color="error"
                    >
                      {quantitiesError}
                    </Typography>
                  )}
                </TableCell>
                <TableCell>
                  <input
                    id=":start-date-input"
                    min={getCurrentDate()}
                    required
                    name="startDate"
                    type="text"
                    className="start-and-end-date"
                    placeholder="Select date"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = e.target.value ? "date" : "text";
                    }}
                    value={formData.startDate}
                    onChange={handleNameChange}
                  />
                </TableCell>
                <TableCell>
                  <input
                    readOnly
                    required
                    id="end-date-input"
                    name="endDate"
                    type="date"
                    className="start-and-end-date"
                    placeholder="End Date"
                    onFocus={(e) => {
                      e.currentTarget.type = "date";
                    }}
                    onBlur={(e) => {
                      e.currentTarget.type = e.target.value ? "date" : "text";
                    }}
                    value={formData.endDate}
                    onChange={handleNameChange}
                  />
                </TableCell>
                <TableCell>
                  <div id="sos-instructions-box" className="sos-instructions">
                    <input
                      id="sos-input"
                      type="checkbox"
                      name="sos"
                      checked={formData.sos}
                      onChange={handleSosChange}
                    />
                    {formData.sos ? (
                      <select
                        id="instructions-input"
                        name="instructions"
                        value={formData.instructions}
                        className="instructions"
                        onChange={handleNameChange}
                      >
                        <option id="select-instructions" value="">
                          {treatmentsText.selectAnOption}
                        </option>
                        {instructions.map((op: any) => (
                          <option
                            id="instruction-value"
                            key={op.value}
                            value={op.value}
                          >
                            {op.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      ""
                    )}
                    {formData.sos ? (
                      <input
                        id="additional-input"
                        name="additional"
                        type="textArea"
                        className="additional-instructions"
                        placeholder="Additional instructions"
                        value={formData.additional}
                        onChange={handleNameChange}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <Button
                    id="add-medication"
                    variant="contained"
                    onClick={addMedicine}
                    disabled={sosCheck()}
                  >
                    {treatmentsText.add}
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {visitCreationStatus ? (
        <Snackbar
          id="visit-created-snackbar"
          autoHideDuration={5000}
          open={openSnackbar}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          sx={{
            "& .MuiAlert-root": { width: 400, height: 50 },
          }}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%", height: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          id="snackbar-message"
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleClose}
          message={message}
          action={action(handleClose)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        />
      )}
      <Box id="lab-suggestions-text" className="tests-and-medications">
        {labSuggestionsText}:
      </Box>
      <TextField
        id="lab-suggestions-input"
        multiline
        fullWidth
        rows={4}
        placeholder="Enter suggestions"
        value={labSuggestions}
        onChange={(e) => setLabSuggestions(e.target.value)}
        inputProps={{
          maxLength: 4000,
        }}
        helperText={`${labSuggestions.length}/4000 characters`}
      />
      <div id="submit-button-box" className="button-container">
        <Button
          id="submit-button"
          variant="contained"
          className="action-button"
          onClick={(e) => {
            handleSubmit(e);
          }}
          disabled={
            !(
              panels?.length > 0 ||
              formData?.drugName?.length > 0 ||
              labSuggestions?.length > 0
            )
          }
        >
          {submit}
        </Button>
        <Backdrop
          id="form-submitted-backdrop"
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={backdropStatus}
        >
          <CircularProgress id="backdrop-progress" color="inherit" />
        </Backdrop>
      </div>
      <MedicationConfirmationDialog
        open={submitDialogOpen}
        handleClose={handleCloseSubmitDialog}
        medicineData={medicines}
        onSubmit={submitVisit}
        testData={selectedTests}
        labSuggestions={labSuggestions}
      />
    </div>
  );
};

export default TreatmentAndMedications;
