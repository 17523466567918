import { useState } from "react";
import { Button, Typography } from "@mui/material";
import { assesmentText } from "../../../utils/constants";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import TableRow from "@mui/material/TableRow";
import Lightbox from "../../LightBox/LightBox";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableContainer from "@mui/material/TableContainer";
import useGetPatientReports from "../../../utils/useGetPatientReports";
import "./InitialAssesment.css";

function InitialAssesment() {
  const [open, setOpen] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const [closeLightbox, setCloseLightbox] = useState<boolean>(true);
  const assessmentData = useGetPatientReports();

  const handleDownload = async (title: string, uri: string) => {
    try {
      const response = await fetch(uri);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = title;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setUrl("");
  };

  return (
    <>
      <div id="assesment-table-box" className="assesment-table">
        <TableContainer
          id="assesment-table-container"
          component={Paper}
          sx={{ maxHeight: 440 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="sl-no"
                    align="center"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{assesmentText.slNo}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="date"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{assesmentText.title}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="doctor-name"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{assesmentText.download}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="view"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{assesmentText.view}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {assessmentData?.length > 0 ? (
                <>
                  {assessmentData?.map((item: any, index: number) => {
                    return (
                      <TableRow key={item?.encounterId}>
                        <TableCell align="center">
                          <Typography id="row-sl-no" variant="body2">
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography id="date" variant="body2">
                            {item?.resource?.content?.[0]?.attachment?.title}
                          </Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Button
                            id="view-button"
                            variant="contained"
                            onClick={() => {
                              handleDownload(
                                item?.resource?.content?.[0]?.attachment?.title,
                                item?.resource?.content?.[0]?.attachment?.url
                              );
                            }}
                          >
                            {assesmentText.download}
                          </Button>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            id="view-button"
                            variant="contained"
                            onClick={() => {
                              setOpen(true);
                              setCloseLightbox(true);
                              setUrl(
                                item?.resource?.content?.[0]?.attachment?.url
                              );
                            }}
                          >
                            {assesmentText.view}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <b id="no-data">{assesmentText.noData}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {url.endsWith(".pdf") && (
        <Dialog
          id="view-dialog"
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="assesment-details"
          fullWidth
          maxWidth="xl"
        >
          <DialogTitle id="assesment-details"></DialogTitle>
          <DialogContent>
            <iframe
              src={url}
              style={{ width: "100%", height: "500px", border: "none" }}
              title="URL Preview"
            />
          </DialogContent>
          <DialogActions>
            <Button id="close-button" onClick={handleDialogClose} autoFocus>
              {assesmentText.close}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {!url.endsWith(".pdf") && closeLightbox && (
        <Lightbox
          image={url}
          title="Image Viewer"
          onClose={() => setCloseLightbox(false)}
        />
      )}
    </>
  );
}

export default InitialAssesment;
