import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IPD_PROJECT_ID, diagnosis, naText } from "../../../utils/constants";
import {
  createApiUrl,
  createFormApiUrl,
  filterEncountersByDate,
  formatReadableDate,
} from "../../../utils/helperFunctions";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import DatePicker from "react-datepicker";
import Dialog from "@mui/material/Dialog";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableContainer from "@mui/material/TableContainer";
import "react-datepicker/dist/react-datepicker.css";
import "./Diagnosis.css";

const DiagnosisCard = ({
  text,
  showFullText,
  setShowFullText,
}: {
  text: string;
  showFullText: boolean;
  setShowFullText: (showFullText: boolean) => void;
}) => {
  return (
    <div id="diagnosis-card-box" className="diagnosis-card">
      {text?.length > 300 ? (
        <p id="diagnosis-type-value">
          {showFullText ? text : text?.substring(0, 300)}
          <span
            id="diagnosis-show-more-button"
            className="show-more"
            onClick={() => setShowFullText(!showFullText)}
          >
            {showFullText ? (
              <i>&nbsp;&nbsp;&nbsp;{diagnosis.showLess}</i>
            ) : (
              diagnosis.showMore
            )}
          </span>
        </p>
      ) : (
        text
      )}
    </div>
  );
};

const Diagnosis = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [diagnosisData, setDiagnosisData] = useState<any>([]);
  const [diagnosisSearchData, setDiagnosisSearchData] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [provisionalDiagnosis, setProvisionalDiagnosis] = useState<string>("");
  const [differentialDiagnosis, setDifferentialDiagnosis] =
    useState<string>("");
  const [investigations, setInvestigations] = useState<string>("");
  const [finalDiagnosis, setFinalDiagnosis] = useState<string>("");
  const [showFullProvisionalDiagnosis, setShowFullProvisionalDiagnosis] =
    useState<boolean>(false);
  const [showFullDifferentialDiagnosis, setShowFullDifferentialDiagnosis] =
    useState<boolean>(false);
  const [showFullInvestigations, setShowFullInvestigations] =
    useState<boolean>(false);
  const [showFullFinalDiagnosis, setShowFullFinalDiagnosis] =
    useState<boolean>(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dateOnChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleEncounterId = (id: string) => {
    setOpen(true);
    setSelectedId(id);
  };

  const handleDataFilterWithDate = () => {
    const data = filterEncountersByDate(diagnosisSearchData, selectedDate);
    setDiagnosisData(data);
  };

  const handleDialogClose = () => {
    setOpen(false);
    setSelectedId("");
    setShowFullProvisionalDiagnosis(false);
    setShowFullDifferentialDiagnosis(false);
    setShowFullInvestigations(false);
    setShowFullFinalDiagnosis(false);
  };

  function getIpdDiagnosisData() {
    const payload = {
      intent: "ipdDiagnosis",
      state: {
        encounterId: selectedId,
      },
      submit: false,
    };
    fetch(createFormApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setProvisionalDiagnosis(data?.state?.provisionalDiagnosis);
        setDifferentialDiagnosis(data?.state?.differentialDiagnosis);
        setInvestigations(data?.state?.investigationsDiagnostics);
        setFinalDiagnosis(data?.state?.finalDiagnosis);
      })
      .catch((err) => console.log(err));
  }

  function fetchEncounterAndDocName() {
    const payload = {
      intent: "getEncounterAndDocName",
      state: {
        patId: patientDetails.patient_uhid,
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setDiagnosisData(data);
        setDiagnosisSearchData(data);
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    getIpdDiagnosisData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  useEffect(() => {
    handleDataFilterWithDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    fetchEncounterAndDocName();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_uhid]);

  return (
    <div id="diagnosis-container">
      <div id="date-picker" className="date-picker-container">
        <span id="select-date-text">{diagnosis.selectDate}:&nbsp;</span>
        <DatePicker
          id="date-picker-input"
          placeholderText="DD-MM-YYYY"
          selected={selectedDate}
          onChange={dateOnChange}
          dateFormat="dd-MM-yyyy"
        />
      </div>
      <div id="diagnosis-table-box" className="diagnosis-table">
        <TableContainer
          id="diagnosis-table-container"
          component={Paper}
          sx={{ maxHeight: 440 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="sl-no"
                    align="center"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{diagnosis.slNo}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="date"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{diagnosis.date}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="left">
                  <Typography
                    id="doctor-name"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{diagnosis.doctorName}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="view"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{diagnosis.view}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {diagnosisData?.length > 0 ? (
                <>
                  {diagnosisData
                    ?.filter(
                      (item) =>
                        item?.practitioner_name !==
                        diagnosis.intensivistCommandCenter
                    )
                    ?.map((item: any, index: number) => {
                      return (
                        <TableRow key={item?.encounterId}>
                          <TableCell align="center">
                            <Typography id="row-sl-no" variant="body2">
                              {index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography id="date" variant="body2">
                              {formatReadableDate(item?.date)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography id="practitioner-name" variant="body2">
                              {item?.practitioner_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Button
                              id="view-button"
                              variant="contained"
                              onClick={() =>
                                handleEncounterId(item?.encounterId)
                              }
                            >
                              {diagnosis.view}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <b id="no-data">{diagnosis.noData}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        id="view-dialog"
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="diagnosis-details"
      >
        <DialogTitle id="diagnosis-details">{"Diagnosis Details"}</DialogTitle>
        <DialogContent>
          <div id="provisional-diagnosis-box">
            <p id="provisional-diagnosis-text">
              <b>{diagnosis.provisionalDiagnosis}:</b>
            </p>
            {provisionalDiagnosis ? (
              <DiagnosisCard
                text={provisionalDiagnosis}
                showFullText={showFullProvisionalDiagnosis}
                setShowFullText={setShowFullProvisionalDiagnosis}
              />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
          <div id="differential-diagnosis-box">
            <p id="differential-diagnosis-text">
              <b>{diagnosis.differentialDiagnosis}:</b>
            </p>
            {differentialDiagnosis ? (
              <DiagnosisCard
                text={differentialDiagnosis}
                showFullText={showFullDifferentialDiagnosis}
                setShowFullText={setShowFullDifferentialDiagnosis}
              />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
          <div id="investigations-box">
            <p id="investigations-text">
              <b>{diagnosis.investigations}:</b>
            </p>
            {investigations ? (
              <DiagnosisCard
                text={investigations}
                showFullText={showFullInvestigations}
                setShowFullText={setShowFullInvestigations}
              />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
          <div id="final-diagnosis-box">
            <p id="final-diagnosis-text">
              <b>{diagnosis.finalDiagnosis}:</b>
            </p>
            {finalDiagnosis ? (
              <DiagnosisCard
                text={finalDiagnosis}
                showFullText={showFullFinalDiagnosis}
                setShowFullText={setShowFullFinalDiagnosis}
              />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button id="close-button" onClick={handleDialogClose} autoFocus>
            {diagnosis.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Diagnosis;
