import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createApiUrl } from "./helperFunctions";
import { OPD_PROJECT_ID } from "./constants";

const useGetPatientReports = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_fhir_id,
  }));
  const [assessmentData, setAssessmentData] = useState<any[]>([]);

  function fetchPatientReports() {
    const payload = {
      intent: "getPatientReports",
      state: {
        patientId: patientDetails.patient_uhid,
      },
    };

    fetch(createApiUrl(OPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setAssessmentData(data?.entry);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchPatientReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_uhid]);

  return assessmentData;
};

export default useGetPatientReports;
