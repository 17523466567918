import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { bedFilter, iPatientListDashboard } from "../../interfaces";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  allocation,
  WEBSOCKET_URL,
  IPD_PROJECT_ID,
  patientDashboard,
} from "../../utils/constants";
import {
  action,
  titleCase,
  checkRanges,
  createApiUrl,
  StyledTableRow,
  StyledTableCell,
  generateRandomID,
} from "../../utils/helperFunctions";
import {
  setPatientUHID,
  setCurrentLocation,
  setSelectedPatientIndex,
} from "../../redux/actions";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Shimmer from "../Shimmer/Shimmer";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import useGetPatientDetails from "../../utils/useGetPatientDetails";
import useGetWardsByLocation from "../../utils/useGetWardsByLocation";
import "./PatientDashboard.css";

const PatientDashboard = () => {
  const dispatch = useDispatch();
  const patientDetails = useSelector((state: any) => ({
    masterPatientDetails: state.masterPatientDetails,
    patient_uhid: state.patient_uhid,
    onlyLocations: state.onlyLocations,
  }));
  const { fetchPatientDetails } = useGetPatientDetails();
  const [patientsList, setPatientsList] = useState<any[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] =
    useState<string>("Amalapuram");
  const [selectedWardID, setSelectedWardID] = useState<any>("-1");
  const [message, setMessage] = useState<string>("");
  const randomID = generateRandomID(4);
  const socketRef = useRef<WebSocket | null>(null);
  const wardDetails = useGetWardsByLocation(selectedLocation);

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const closeWebSocket = () => {
    if (socketRef.current) {
      socketRef.current.close();
      console.log("WebSocket connection closed");
    }
  };

  const handleViewInPOD = (targetPatientID: string) => {
    if (
      !patientDetails.masterPatientDetails
        ?.flat()
        ?.find((item) => item?.patientId === targetPatientID)
    ) {
      setOpenSnackbar(true);
      setMessage(patientDashboard.patientNotFoundInPod);
      return;
    }
    setOpenSnackbar(true);
    setMessage(patientDashboard.openingPatientInPod);
    const locationArray = patientDetails.masterPatientDetails?.find(
      (subArray) =>
        subArray?.some((patient) => patient?.patientId === targetPatientID)
    );
    const patientIndex = locationArray?.findIndex(
      (item) => item?.patientId === targetPatientID
    );
    const patientLocation = locationArray?.[0]?.location;
    dispatch(setPatientUHID(targetPatientID));
    dispatch(setCurrentLocation(patientLocation));
    dispatch(setSelectedPatientIndex(patientIndex));
    fetchPatientDetails(targetPatientID);
  };

  function fetchBedDetailsByLocationAndWards() {
    const payload = {
      intent: "getBedDetailsByLocationAndWards",
      state: { wardId: Number(selectedWardID), locationId: selectedLocation },
    };

    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        const result: bedFilter[] = data?.ward_details?.flatMap((ward: any) => {
          return ward?.room_details?.flatMap((room: any) => {
            return room?.beds
              ?.filter((bed) => bed.status === allocation.occupied)
              ?.map((bed: any) => {
                const status = bed?.bed_patient_assignment_maps?.find(
                  (status: any) => status?.bed_status === allocation?.assigned
                );
                return {
                  id: bed?.id,
                  bedNumber: bed?.bed_number,
                  name: titleCase(bed?.patient_name),
                  admission: bed?.admission_date ?? "",
                  categoryName: bed?.bed_category?.name ?? "",
                  dischargedDate: bed?.discharged_date ?? "",
                  bedCategory:
                    bed?.bed_category_id === 3
                      ? allocation.critical
                      : allocation.nonCritical,
                  status: bed?.status,
                  roomId: bed?.room_id,
                  mrNumber: bed?.patient_id,
                  roomName: room?.room_name,
                  roomNumber: room?.room_number,
                  roomDetail: `${room?.room_name} ${room?.room_number ?? ""}`,
                  locationId: room?.location_id,
                  wardName: ward?.ward_name,
                  floorNumber: ward?.floor_number,
                  bedPatientMapId: status?.bed_id,
                  bedPatientId: status?.id,
                  bedPatientAssignedId: status?.patient_id,
                  bedPatientStatus: status?.bed_status,
                  bedPatientDischargeDate: status?.date_of_discharge,
                  ecgHeartRate: null,
                  systolicBp: null,
                  diastolicBp: null,
                  spo2: null,
                  respiratoryRate: null,
                };
              });
          });
        });
        setPatientsList(result);
      })
      .catch((err) => console.error(err));
  }

  const createSocketConnection = () => {
    const socket = new WebSocket(
      `${WEBSOCKET_URL}websocket?deviceId=all${randomID}&patientId=all${randomID}`
    );
    socketRef.current = socket;

    socket.onopen = function (event) {
      console.log("WebSocket connection opened", event);
    };

    socket.onmessage = function (event) {
      const message = JSON.parse(event.data);
      console.log(message);
      if (message?.patientId) {
        setDataLoaded(true);
      }
      const formattedData = {
        mrNumber: message?.patientId,
        name: patientDetails.masterPatientDetails
          ?.flat()
          ?.find((item) => item?.patientId === message?.patientId)?.patientName,
        ecgHeartRate: message?.ecgHeartRate,
        systolicBp:
          message?.systolicBpValue > 0 ? message?.systolicBpValue : "-",
        diastolicBp:
          message?.diastolicBpValue > 0 ? message?.diastolicBpValue : "-",
        spo2: message?.spo2 > 0 ? message?.spo2 : "-",
        respiratoryRate:
          message?.respiratoryValue > 0 ? message?.respiratoryValue : "-",
      };

      setPatientsList((prevList) => {
        const existingPatientIndex = prevList?.findIndex(
          (patient) => patient.mrNumber === formattedData.mrNumber
        );

        if (existingPatientIndex !== -1) {
          const updatedPatientData = [...prevList];
          if (formattedData.ecgHeartRate > 0) {
            updatedPatientData[existingPatientIndex].ecgHeartRate =
              formattedData.ecgHeartRate;
          }
          if (formattedData.systolicBp > 0) {
            updatedPatientData[existingPatientIndex].systolicBp =
              formattedData.systolicBp;
          }
          if (formattedData.diastolicBp > 0) {
            updatedPatientData[existingPatientIndex].diastolicBp =
              formattedData.diastolicBp;
          }
          if (formattedData.spo2 > 0) {
            updatedPatientData[existingPatientIndex].spo2 = formattedData.spo2;
          }
          if (formattedData.respiratoryRate > 0) {
            updatedPatientData[existingPatientIndex].respiratoryRate =
              formattedData.respiratoryRate;
          }
          return updatedPatientData;
        } else {
          return prevList;
        }
      });
    };

    socket.onerror = function (event) {
      console.error("WebSocket error observed:", event);
    };

    socket.onclose = function (event) {
      console.log("Websocket closure code:", event.code);
      if (event.code !== 1000 && event.code !== 1001) {
        alert(patientDashboard.refreshPageMessage);
      }
    };
  };

  useEffect(() => {
    createSocketConnection();
    return () => {
      closeWebSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchBedDetailsByLocationAndWards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWardID, selectedLocation]);

  return (
    <>
      <Grid container columns={16}>
        <Grid item xs={0} sm={0} md={3} lg={6} xl={7}></Grid>
        <Grid item xs={16} sm={4} md={3} lg={2} xl={2}>
          <div className="dropdown-title">
            {patientDashboard.selectLocation}:
          </div>
        </Grid>
        <Grid item xs={16} sm={12} md={3} lg={3} xl={2}>
          <Select
            fullWidth
            size="small"
            id="location-selection"
            className="location-ward-filter"
            value={selectedLocation}
            onChange={(e: SelectChangeEvent) => {
              setSelectedLocation(e.target.value);
              setSelectedWardID("-1");
            }}
          >
            {patientDetails.onlyLocations?.map((item) => (
              <MenuItem id="selected-location" value={item} key={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={16} sm={4} md={2} lg={2} xl={2}>
          <div className="dropdown-title">{patientDashboard.selectWard}:</div>
        </Grid>
        <Grid item xs={16} sm={12} md={5} lg={3} xl={3}>
          <Select
            fullWidth
            size="small"
            id="ward-selection"
            className="location-ward-filter"
            value={selectedWardID}
            onChange={(e: SelectChangeEvent) =>
              setSelectedWardID(e.target.value)
            }
          >
            <MenuItem id="no-ward-selected" value="-1">
              {patientDashboard.wardSelection}
            </MenuItem>
            {wardDetails?.length > 0
              ? wardDetails?.map((item) => (
                  <MenuItem id="selected-ward" value={item?.id} key={item?.id}>
                    {item?.ward_name}
                  </MenuItem>
                ))
              : patientDashboard.noWardsAvailable}
          </Select>
        </Grid>
      </Grid>
      <div className="patient-dashboard-table-container">
        <TableContainer id="patient-dashboard-table" component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#1b5299" }}>
                  <Typography
                    id="mr-number-text"
                    align="center"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.mrNumber}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }}>
                  <Typography
                    id="patient-name-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.name}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }} align="center">
                  <Typography
                    id="ecg-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.ecg}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }} align="center">
                  <Typography
                    id="bp-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.bp}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }} align="center">
                  <Typography
                    id="spo2-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.spo2}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }} align="center">
                  <Typography
                    id="respiratory-rate-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.respiratoryRate}
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#1b5299" }} align="center">
                  <Typography
                    id="actions-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    {patientDashboard.actions}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientsList?.length > 0 ? (
                <>
                  {patientsList
                    ?.filter(
                      (bed) => bed?.categoryName === patientDashboard.critical
                    )
                    ?.map((item: iPatientListDashboard) => {
                      return (
                        <StyledTableRow key={item?.mrNumber}>
                          <StyledTableCell
                            sx={{ background: "#04325f" }}
                            align="center"
                          >
                            <Typography
                              className="table-cell-text"
                              id="mr-number-value"
                              variant="body2"
                            >
                              {item?.mrNumber}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ background: "#04325f" }}
                            align="left"
                          >
                            <Typography
                              className="table-cell-text"
                              id="patient-name-value"
                              variant="body2"
                            >
                              {item?.name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.ecgHeartRate ? (
                              <Typography
                                className={
                                  parseInt(item?.ecgHeartRate) >= 60 &&
                                  parseInt(item?.ecgHeartRate) <= 100
                                    ? "normal-value"
                                    : "abnormal-value"
                                }
                                id="ecgHeartrate-value"
                                variant="body2"
                              >
                                {item?.ecgHeartRate}
                              </Typography>
                            ) : dataLoaded ? (
                              <Typography
                                className="abnormal-value"
                                variant="body2"
                              >
                                -
                              </Typography>
                            ) : (
                              <Shimmer
                                height={40}
                                width={60}
                                borderRadius={40}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.systolicBp && item?.diastolicBp ? (
                              <Typography
                                className={
                                  checkRanges(
                                    parseInt(item?.systolicBp),
                                    parseInt(item?.diastolicBp)
                                  )
                                    ? "normal-value"
                                    : "abnormal-value"
                                }
                                id="bp-value"
                                variant="body2"
                              >
                                {item?.systolicBp}/{item?.diastolicBp}
                              </Typography>
                            ) : dataLoaded ? (
                              <Typography
                                className="abnormal-value"
                                variant="body2"
                              >
                                -
                              </Typography>
                            ) : (
                              <Shimmer
                                height={40}
                                width={100}
                                borderRadius={40}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.spo2 ? (
                              <Typography
                                className={
                                  parseInt(item?.spo2?.toString()) >= 90 &&
                                  parseInt(item?.spo2?.toString()) <= 100
                                    ? "normal-value"
                                    : "abnormal-value"
                                }
                                id="spo2-value"
                                variant="body2"
                              >
                                {item?.spo2}
                              </Typography>
                            ) : dataLoaded ? (
                              <Typography
                                className="abnormal-value"
                                variant="body2"
                              >
                                -
                              </Typography>
                            ) : (
                              <Shimmer
                                height={40}
                                width={50}
                                borderRadius={4}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item?.respiratoryRate ? (
                              <Typography
                                className={
                                  parseInt(item?.respiratoryRate?.toString()) >=
                                    8 &&
                                  parseInt(item?.respiratoryRate?.toString()) <=
                                    30
                                    ? "normal-value"
                                    : "abnormal-value"
                                }
                                id="respiratory-rate-value"
                                variant="body1"
                              >
                                {item?.respiratoryRate}
                              </Typography>
                            ) : dataLoaded ? (
                              <Typography
                                className="abnormal-value"
                                variant="body2"
                              >
                                -
                              </Typography>
                            ) : (
                              <Shimmer
                                height={40}
                                width={50}
                                borderRadius={4}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              id="view-button"
                              variant="contained"
                              onClick={() => handleViewInPOD(item?.mrNumber)}
                            >
                              {patientDashboard.view}
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={8}>
                    <b id="no-data">{patientDashboard.loadingPatients}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Snackbar
        id="snackbar-message"
        message={message}
        open={openSnackbar}
        onClose={handleClose}
        autoHideDuration={3000}
        action={action(handleClose)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </>
  );
};

export default PatientDashboard;
