import { Box } from "@mui/material";
import { JaaSMeeting } from "@jitsi/react-sdk";
import { fetchJitsiToken } from "../../utils/services";
import { SyntheticEvent, useEffect, useState } from "react";
import { videoCall, JITSI_APP_ID } from "../../utils/constants";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import "./PatientVideo.css";

const PatientVideo = () => {
  const [roomName, setRoomName] = useState<string>("");
  const [roomNameDropdown, setRoomNameDropdown] = useState<string>("");
  const [showJitsi, setShowJitsi] = useState<boolean>(false);
  const [isSelectExistingRoom, setIsSelectExistingRoom] =
    useState<boolean>(false);
  const [message] = useState<string>(videoCall.recordingFeatureUnavailable);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [jwtToken, setJwtToken] = useState<string>("");

  const startJitstMeet = () => {
    if (roomName.length > 12 || roomNameDropdown.length > 0) {
      setShowJitsi(true);
    }
  };

  const handleRoomNameChange = (input) => {
    const regex = /^[A-Za-z]*$/;
    if (regex.test(input)) {
      setRoomName(input);
    }
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const fetchToken = async () => {
    try {
      const token = await fetchJitsiToken();
      if (token) {
        setJwtToken(token);
      } else {
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.log(error);
      setOpenSnackbar(true);
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  return (
    <div id="jitsi-video" className="patient-video">
      {showJitsi ? (
        <JaaSMeeting
          jwt={jwtToken}
          appId={JITSI_APP_ID}
          roomName={isSelectExistingRoom ? roomNameDropdown : roomName}
          configOverwrite={{
            startAudioMuted: 10,
            startVideoMuted: 10,
            backgroundAlpha: 0.5,
            startWithAudioMuted: false,
            startWithVideoMuted: false,
            disableLocalVideoFlip: true,
            enableNoAudioDetection: true,
            enableNoisyMicDetection: true,
            disableThirdPartyRequests: true,
            recordingService: {
              enabled: true,
            },
            recordings: {
              suggestRecording: true,
              showPrejoinWarning: true,
              recordAudioAndVideo: true,
            },
          }}
          getIFrameRef={(iframeRef) => {
            iframeRef.style.height = "100vh";
            iframeRef.style.width = "100vw";
          }}
          onReadyToClose={() => window.close()}
        />
      ) : (
        <div className="start-video-call">
          <div>
            <Typography variant="h4" gutterBottom color="#555">
              {videoCall.startVideoCall}
            </Typography>
            <TextField
              fullWidth
              value={roomName}
              variant="outlined"
              id="enter-room-name"
              helperText={videoCall.roomNameHelperText}
              placeholder={videoCall.enterRoomPlaceholder}
              onChange={(e) => handleRoomNameChange(e.target.value)}
            />
            <Box sx={{ textAlign: "center", marginY: "10px" }}>
              {videoCall.or}
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="select-existing-room"
                    value={isSelectExistingRoom}
                    onChange={() =>
                      setIsSelectExistingRoom(!isSelectExistingRoom)
                    }
                  />
                }
                label={videoCall.selectExistingRoom}
              />
            </FormGroup>
            {isSelectExistingRoom && (
              <FormControl fullWidth>
                <InputLabel id="room-name-select">
                  {videoCall.selectARoom}
                </InputLabel>
                <Select
                  id="room-name-select"
                  value={roomNameDropdown}
                  label={videoCall.selectRoom}
                  onChange={(e) => setRoomNameDropdown(e.target.value)}
                >
                  <MenuItem value="CommandCenterPodOne">
                    {videoCall.pod1}
                  </MenuItem>
                  <MenuItem value="CommandCenterPodTwo">
                    {videoCall.pod2}
                  </MenuItem>
                  <MenuItem value="CommandCenterConferenceRoom">
                    {videoCall.conferenceRoom}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
            <Button
              fullWidth
              variant="contained"
              sx={{ marginY: "10px" }}
              onClick={startJitstMeet}
              disabled={roomName.length < 12 && roomNameDropdown.length < 1}
            >
              {videoCall.startVideoCall}
            </Button>
          </div>
        </div>
      )}
      <Snackbar
        id="recording-feature-unavailable"
        autoHideDuration={5000}
        open={openSnackbar}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="warning" variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PatientVideo;
