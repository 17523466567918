export const DayProgress = ({ activeDays }: any) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const radius = 5;
  const strokeWidth = 2;
  const circleSpacing = 30;

  return (
    <svg
      id="medication-progress"
      height="70"
      width={days.length * circleSpacing}
    >
      <line
        x1={radius}
        y1="35"
        x2={days.length * circleSpacing - radius - 15}
        y2="35"
        stroke="grey"
        strokeWidth={strokeWidth}
      />
      {days.map((day, index) => (
        <g key={day}>
          <text
            x={index * circleSpacing + radius + 4}
            y="15"
            textAnchor="middle"
            fill="black"
            fontSize="10"
          >
            {day}
          </text>
          <circle
            cx={index * circleSpacing + radius}
            cy="35"
            r={radius}
            fill={activeDays.includes(index) ? "green" : "grey"}
          />
        </g>
      ))}
    </svg>
  );
};

export const MealProgress = ({ activeMeals }: any) => {
  const meals = ["breakfast", "lunch", "dinner"];
  const times = ["before", "after"];
  const radius = 5;
  const strokeWidth = 2;
  const circleSpacing = 30;
  const mealSpacing = 80;

  const isActive = (meal: any, time: any) => {
    return activeMeals?.some(
      (active: any) => active?.meal === meal && active?.time === time
    );
  };

  const totalWidth =
    meals.length * mealSpacing + (times.length - 1) * circleSpacing - 60;
  const lastCircleX =
    (meals.length - 1) * mealSpacing +
    (times.length - 1) * circleSpacing +
    radius;

  return (
    <svg id="meal-progress" height="50" width={totalWidth}>
      <line
        x1={radius}
        y1="30"
        x2={lastCircleX}
        y2="30"
        stroke="grey"
        strokeWidth={strokeWidth}
      />
      {meals?.map((meal, mealIndex) => (
        <g key={meal}>
          <text
            x={mealIndex * mealSpacing + circleSpacing - 8}
            y="15"
            textAnchor="middle"
            fill="black"
            fontSize="10"
          >
            {meal?.charAt(0)?.toUpperCase() + meal?.slice(1)}
          </text>
          {times?.map((time, timeIndex) => (
            <g key={time}>
              <circle
                cx={
                  mealIndex * mealSpacing + timeIndex * circleSpacing + radius
                }
                cy="30"
                r={radius}
                fill={isActive(meal, time) ? "green" : "grey"}
              />
            </g>
          ))}
        </g>
      ))}
    </svg>
  );
};
