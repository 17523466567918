import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { createApiUrl, titleCase } from "../../../utils/helperFunctions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Box,
  Dialog,
  TextField,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  TextFieldProps,
} from "@mui/material";
import {
  Step,
  iMedication,
  AdministeredData,
  MedicationAdministrationTimes,
} from "../../../interfaces";
import {
  IPD_PROJECT_ID,
  medicationAdministration,
} from "../../../utils/constants";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import TableContainer from "@mui/material/TableContainer";
import "./MedicationAdministration.css";

const Timeline = ({ steps }: { steps: Step[] }) => {
  return (
    <Box id="timeline-box" className="timeline-container-administration">
      {steps?.map((step, index) => (
        <Box
          id="timeline-step"
          key={index}
          className={`timeline-step-administration ${
            step?.completed ? "completed" : ""
          }`}
        >
          <Typography id="timeline-line" variant="body2" textAlign="center">
            <span className="administration-timeline-time">{step?.time}</span>
            &nbsp;
            {step?.comments && <span>|&nbsp;{step?.comments} </span>}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const MedicationAdministration = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const [filterDate, setFilterDate] = useState<Dayjs | null>(dayjs());
  const [tableData, setTableData] = useState<iMedication[]>([]);
  const [selectedMedicine, setSelectedMedicine] = useState<string>("");
  const [openAdministerView, setOpenAdministerView] = useState<boolean>(false);
  const [medicationAdministerSlots, setMedicationAdministerSlots] =
    useState<MedicationAdministrationTimes>({});
  const dates = Object.keys(medicationAdministerSlots);

  const handleClickAdministerView = (row: iMedication) => {
    fetchMedicationAdministrationWithId(row?.id);
    setSelectedMedicine(row?.drug_name);
    setOpenAdministerView(true);
  };

  const handleCloseAdministerView = () => {
    setOpenAdministerView(false);
    setMedicationAdministerSlots({});
  };

  const fetchMedicationAdministration = () => {
    const payloadForDocId = {
      intent: "getMedicineAdministration",
      state: {
        patId: patientDetails.patient_uhid,
        given_date: filterDate ? dayjs(filterDate).format("YYYY-MM-DD") : "",
        end_date: filterDate ? dayjs(filterDate).format("YYYY-MM-DD") : "",
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForDocId),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.length > 0) {
          setTableData(data);
        } else {
          setTableData(data);
        }
      })
      .catch((err) => console.error(err));
  };

  const fetchMedicationAdministrationWithId = (id: number) => {
    const payloadForDocId = {
      intent: "getAdministerMedicineById",
      state: {
        medication_id: id,
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForDocId),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.medicine_administration?.length > 0) {
          const result: { [key: string]: any[] } = {};
          data?.medicine_administration?.forEach((item: AdministeredData) => {
            const localTime = item?.administeredTime;
            const date = item?.administeredDate;

            if (!result[date]) {
              result[date] = [];
            }

            result[date].push({
              time: moment
                .parseZone(localTime, "HH:mm:ssZ")
                .local()
                .format("hh:mm A"),
              completed: true,
              comments: item?.comments,
            });
          });
          setMedicationAdministerSlots(result);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchMedicationAdministration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_uhid, filterDate]);

  return (
    <div id="medication-administrator-container">
      <Dialog
        id="administer-view-dialog"
        fullWidth
        disableScrollLock
        maxWidth="lg"
        open={openAdministerView}
        onClose={handleCloseAdministerView}
      >
        <DialogTitle id="selected-medicine">{selectedMedicine}</DialogTitle>
        <IconButton
          id="close-administer-view"
          size="large"
          aria-label="close"
          onClick={handleCloseAdministerView}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
        <DialogContent>
          {dates?.map((date: string) => (
            <div id="timeline-content" key={date}>
              <div className="administration-timeline-date">{date}</div>
              <Timeline steps={medicationAdministerSlots[date]} />
            </div>
          ))}
        </DialogContent>
      </Dialog>
      <div id="date-picker-filter" className="date-picker-container">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            key="filter-picker"
            label="Date"
            value={filterDate}
            format="DD/MM/YYYY"
            onChange={(newValue) => setFilterDate(newValue)}
            className="custom-date-picker-administration"
            slots={{
              textField: (params: TextFieldProps) => (
                <TextField {...params} size="small" />
              ),
            }}
          />
        </LocalizationProvider>
      </div>
      <div
        id="medication-administration-table-box"
        className="medication-administration-table"
      >
        <TableContainer
          id="medication-administration-table-container"
          component={Paper}
          sx={{ maxHeight: 440 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="medicine-text"
                    align="left"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationAdministration.medicine}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="dosage-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationAdministration.dosage}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    align="center"
                    id="duration-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationAdministration.duration}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="route-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationAdministration.route}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="frequency-text"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{medicationAdministration.frequency}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.length > 0 ? (
                <>
                  {tableData?.map((row: any) => {
                    return (
                      <TableRow key={row?.encounterId}>
                        <TableCell>
                          <Typography
                            id="drug-name"
                            variant="body2"
                            align="left"
                          >
                            {row?.drug_name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography id="dose" variant="body2" align="left">
                            {row?.dose}&nbsp;{row?.dose_units}(s)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            id="duration-and-units"
                            variant="body2"
                            align="center"
                          >
                            {row?.duration}&nbsp;{row?.duration_units}(s)
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography id="route" variant="body2" align="center">
                            {titleCase(row?.route)}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography id="frequency" variant="body2">
                            {titleCase(row?.frequency)}
                          </Typography>
                          <IconButton
                            id="view-administer"
                            onClick={() => handleClickAdministerView(row)}
                          >
                            <InfoIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <b id="no-data">{medicationAdministration.noData}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default MedicationAdministration;
