import { useEffect, useState } from "react";
import { WardDetail } from "../interfaces";
import { createApiUrl } from "./helperFunctions";
import { IPD_PROJECT_ID } from "./constants";

const useGetWardsByLocation = (location: string) => {
  const [wardDetails, setWardDetails] = useState<WardDetail[]>([]);

  function fetchWardsByLocation() {
    const payload = {
      intent: "getWardsByLocation",
      state: {
        locationId: location,
      },
    };

    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setWardDetails(data?.ward_details || []);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchWardsByLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return wardDetails;
};

export default useGetWardsByLocation;
