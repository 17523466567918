import "./Shimmer.css";

const Shimmer = ({ height, width, borderRadius }) => {
  const shimmerStyle = {
    height: `${height}px`,
    width: `${width}px`,
    borderRadius: `${borderRadius}px`,
  };
  const shimmerContainer = {
    display: "flex",
    justifyContent: "center",
  };
  return (
    <div style={shimmerContainer}>
      <div className="shimmer" style={shimmerStyle}></div>
    </div>
  );
};

export default Shimmer;
