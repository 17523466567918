import { useEffect, useState } from "react";
import { Order } from "../interfaces";
import { createApiUrl } from "./helperFunctions";
import { IPD_PROJECT_ID } from "./constants";

const useGetLabOrdersForIPD = (patientId: string) => {
  const [labOrders, setLabOrders] = useState<Order[]>([]);

  const fetchAllLabOrders = () => {
    const payload = {
      intent: "getLabOrdersForIPD",
      state: { patId: patientId },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => {
        if (!res.ok) {
          setLabOrders([]);
        }
        return res.json();
      })
      .then((data) => {
        if (data?.orders?.length > 0) {
          const labOrderId = data?.orders[0]?.id;
          setLabOrders(data?.orders);
          return labOrderId;
        } else {
          setLabOrders([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    fetchAllLabOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return labOrders;
};

export default useGetLabOrdersForIPD;
