import { useEffect } from "react";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { CONSTANTS } from "./utils/constants";
import { useLocation } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import Sidebar from "./components/Sidebar/Sidebar";
import Navigator from "./components/navigation/Navigator";
import RenderOnAuthenticated from "./components/RenderOnAuthenticated";
import "./App.css";

const App = () => {
  const location = useLocation();
  const hiddenSidebarRoutes = ["/videoCall"];

  const setTokens = (): void => {
    const { token, refreshToken, idTokenParsed } = keycloak;
    if (token && refreshToken && idTokenParsed) {
      sessionStorage.setItem(CONSTANTS.USER_EMAIL, idTokenParsed.email);
      sessionStorage.setItem(CONSTANTS.FIRST_NAME, idTokenParsed.given_name);
      sessionStorage.setItem(CONSTANTS.LAST_NAME, idTokenParsed.family_name);
      sessionStorage.setItem(CONSTANTS.USER_ID, idTokenParsed.sub);
      sessionStorage.setItem(CONSTANTS.REACT_TOKEN, token);
      sessionStorage.setItem(CONSTANTS.USER_TYPE, "user");
    }
  };
  const refreshAccessToken = (): void => {
    keycloak
      .updateToken(50)
      .then((refreshed: boolean) => {
        if (refreshed) {
          setTokens();
        }
      })
      .catch(() => {
        sessionStorage.clear();
        keycloak.logout();
      });
  };

  const handleEvent = (event: string): void => {
    if (event === "onAuthSuccess") {
      if (window.location.href.indexOf("signup") > -1) {
        window.location.href = "/";
      }
      setTokens();
    }

    if (event === "onTokenExpired") {
      refreshAccessToken();
    }

    if (event === "onAuthLogout") {
      sessionStorage.clear();
    }
  };

  const getAppChildren = () => (
    <div className="command-center" id="command-center-app">
      <Provider store={store}>
        {!hiddenSidebarRoutes.includes(location.pathname) && <Sidebar />}
        <Navigator />
      </Provider>
    </div>
  );

  const IDLE_TIMEOUT = 4 * 60 * 1000; // 30 minutes

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAccessToken();
    }, 2 * 60 * 1000); // Call every 4 minutes

    const events = ["mousemove", "keydown", "click"];
    let idleTimeoutId;

    const resetIdleTimer = () => {
      clearTimeout(idleTimeoutId);
      idleTimeoutId = setTimeout(() => {
        refreshAccessToken();
      }, IDLE_TIMEOUT);
    };

    events.forEach((event) => {
      window.addEventListener(event, resetIdleTimer);
    });

    resetIdleTimer();

    return () => {
      clearInterval(intervalId);
      events.forEach((event) => {
        window.removeEventListener(event, resetIdleTimer);
      });
      clearTimeout(idleTimeoutId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReactKeycloakProvider
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
      authClient={keycloak}
      onEvent={handleEvent}
    >
      <RenderOnAuthenticated>{getAppChildren()}</RenderOnAuthenticated>
    </ReactKeycloakProvider>
  );
};

export default App;
