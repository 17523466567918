import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createApiUrl } from "./helperFunctions";
import { IPD_PROJECT_ID } from "./constants";

const useFetchObservationsForCC = (
  minus8Hours: string,
  currentUtcDateTime: string
) => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const [historyData, setHistoryData] = useState<any[]>([]);

  function fetchObservationsForCC() {
    const payload = {
      intent: "fetchObservationsForCC",
      state: {
        patId: patientDetails.patient_uhid,
        startTime: minus8Hours,
        endTime: currentUtcDateTime,
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setHistoryData(data?.entry);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    fetchObservationsForCC();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_uhid]);

  return historyData;
};

export default useFetchObservationsForCC;
