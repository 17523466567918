import { SyntheticEvent, useState } from "react";
import { iLabReports } from "../../../interfaces";
import { useSelector } from "react-redux";
import {
  LABS_PROJECT_ID,
  downloadUrl,
  labReports,
  tests,
} from "../../../utils/constants";
import {
  action,
  createApiUrl,
  dateDifference,
  formatReadableDate,
} from "../../../utils/helperFunctions";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import Snackbar from "@mui/material/Snackbar";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import useGetLabOrdersForIPD from "../../../utils/useGetLabOrdersForIPD";
import "./LabReports.css";

function Row({ row, index, setOpenSnackbar, setMessage }: iLabReports) {
  const [open, setOpen] = useState<boolean>(false);

  function fetchDocumentIDForLabOrders() {
    const payloadForDocId = {
      intent: "getDocIdForLabOrder",
      state: {
        id: row?.id,
      },
    };
    fetch(createApiUrl(LABS_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForDocId),
    })
      .then((res) => res.json())
      .then((data) => {
        const documentId = data?.lab_orders_by_pk?.document_id;
        return documentId;
      })
      .then(async (documentId) => {
        if (!documentId) {
          setOpenSnackbar(true);
          setMessage(labReports.reportUnavailableMessage);
          return;
        }
        const response = await fetch(downloadUrl + documentId, {
          method: "GET",
        });
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const iframe = document.createElement("iframe");
        iframe.src = blobUrl;
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.style.border = "none";

        const newWindow = window.open("", "_blank") as Window;
        newWindow.document.body.style.margin = "0";
        newWindow.document.body.appendChild(iframe);
        newWindow.document.title = "Test Results";
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell align="center">
          <IconButton
            id="expand-toggle"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center">
          <Typography id="sl-no" variant="body2">
            {index + 1}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography id="lr-number" variant="body2">
            {row?.lr_number}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography id="requested-by" variant="body2">
            {row?.requested_by}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography id="status" variant="body2">
            {row?.status}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography id="tat" variant="body2">
            {dateDifference(row?.ordered_on, row?.validation_completed_on)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <IconButton
            id="view-report-button"
            onClick={() => fetchDocumentIDForLabOrders()}
            disabled={row?.status !== labReports.completed}
          >
            <ArticleOutlinedIcon id="view-icon" />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box id="expanded-table-container" sx={{ margin: 1 }}>
              <Typography
                id="tests-text"
                variant="h6"
                gutterBottom
                component="div"
              >
                {tests}
              </Typography>
              <Table
                id="expanded-table-small"
                size="small"
                aria-label="purchases"
                sx={{ border: "1px solid #ddd" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell id="test-name">{labReports.test}</TableCell>
                    <TableCell id="doctor-name" align="left">
                      {labReports.doctor}
                    </TableCell>
                    <TableCell id="date-and-time" align="left">
                      {labReports.dateAndTime}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.panels?.map((panel: any) => (
                    <TableRow key={panel?.id}>
                      <TableCell id="test-value" component="th" scope="row">
                        {panel?.name}
                      </TableCell>
                      <TableCell id="doctor-value" align="left">
                        {row?.requested_by}
                      </TableCell>
                      <TableCell id="date-and-time-value" align="left">
                        {formatReadableDate(row?.ordered_on)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const LabReports = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const labOrders = useGetLabOrdersForIPD(patientDetails.patient_uhid);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const handleSnackbarClose = (
    event: SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div id="lab-reports-container" className="lab-reports-table">
      <TableContainer
        id="labReport-table"
        component={Paper}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ background: "#004c91" }} align="center">
                <Typography variant="body1" className="table-heading-text">
                  -
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="center">
                <Typography
                  id="sl-no"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.slNo}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }}>
                <Typography
                  id="lab-id"
                  align="center"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.labId}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="requested-by"
                  align="center"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.requestedBy}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="status"
                  align="center"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.status}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="center">
                <Typography
                  id="tat"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.tat}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="view-report"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReports.view}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {labOrders?.length ? (
              labOrders?.map((row: any, index: number) => (
                <Row
                  key={row?.id}
                  row={row}
                  index={index}
                  setOpenSnackbar={setOpenSnackbar}
                  setMessage={setMessage}
                />
              ))
            ) : (
              <TableRow>
                <TableCell id="no-lab-orders" colSpan={7}>
                  <b>{labReports.noLabOrdersFound}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        id="snackbar-for-lab-reports"
        autoHideDuration={3000}
        open={openSnackbar}
        onClose={handleSnackbarClose}
        message={message}
        action={action(handleSnackbarClose)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </div>
  );
};

export default LabReports;
