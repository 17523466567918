import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Appointment } from "../interfaces";
import { createApiUrl } from "./helperFunctions";
import { IPD_PROJECT_ID } from "./constants";

const useGetAllSubEncounters = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_fhir_id: state.patient_fhir_id,
  }));

  const [allAppointments, setAllAppointments] = useState<Appointment[]>([]);

  function fetchAllSubencounters() {
    const payload = {
      intent: "getAllSubEncounters",
      state: { patientId: patientDetails.patient_fhir_id },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        const appointments = data?.entry?.map((entry: any) => ({
          id: entry?.resource?.id,
          resource: entry?.resource,
        }));
        setAllAppointments(appointments);
      })
      .catch((err) =>
        console.error("Error communicating with server. Error code: ", err)
      );
  }

  useEffect(() => {
    fetchAllSubencounters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails.patient_fhir_id]);

  return allAppointments;
};

export default useGetAllSubEncounters;
