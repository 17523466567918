import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createStore, applyMiddleware } from "redux";
import { persistReducer } from "redux-persist";
import { loadingActionReducer } from "./reducer";
import { LAYOUT } from "./actions";
import {
  createStateSyncMiddleware,
  initMessageListener,
} from "redux-state-sync";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, loadingActionReducer);

const reduxStateSyncConfig = {
  blacklist: [LAYOUT],
  // you can specify any configurations
};

//redux-state-sync middleware
const stateSyncMiddleware = createStateSyncMiddleware(reduxStateSyncConfig);

const store = createStore(
  persistedReducer,
  applyMiddleware(stateSyncMiddleware)
);

initMessageListener(store);

export { store };
