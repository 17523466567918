import { useState } from "react";
import StyledTab from "./StyledTab";
import StyledTabs from "./StyledTabs";
import CustomTabPanel from "./CustomTabPanel";
import Diagnosis from "./Diagnosis/Diagnosis";
import LabReports from "./LabReports/LabReports";
import Medications from "./Medications/Medications";
import TreatmentPlan from "./TreatmentPlan/TreatmentPlan";
import InitialAssesment from "./InitialAssesment/InitialAssesment";
import MedicationAdministration from "./MedicationAdministration/MedicationAdministration";
import "./PatientReports.css";

const PatientReports = () => {
  const [reportsTabValue, setReportsTabValueValue] = useState<number>(0);

  const handleReportsTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setReportsTabValueValue(newValue);
  };

  return (
    <div id="patient-reports-container" className="patient-reports">
      <StyledTabs
        sx={{ bgcolor: "#1B5299" }}
        value={reportsTabValue}
        onChange={handleReportsTabChange}
      >
        <StyledTab label="Lab Reports" />
        <StyledTab label="Medications" />
        <StyledTab label="Diagnosis" />
        <StyledTab label="Treatment Plan" />
        <StyledTab label="Initial Assesment" />
        <StyledTab label="Medication Administration" />
      </StyledTabs>
      <CustomTabPanel value={reportsTabValue} index={0}>
        <LabReports />
      </CustomTabPanel>
      <CustomTabPanel value={reportsTabValue} index={1}>
        <Medications />
      </CustomTabPanel>
      <CustomTabPanel value={reportsTabValue} index={2}>
        <Diagnosis />
      </CustomTabPanel>
      <CustomTabPanel value={reportsTabValue} index={3}>
        <TreatmentPlan />
      </CustomTabPanel>
      <CustomTabPanel value={reportsTabValue} index={4}>
        <InitialAssesment />
      </CustomTabPanel>
      <CustomTabPanel value={reportsTabValue} index={5}>
        <MedicationAdministration />
      </CustomTabPanel>
    </div>
  );
};

export default PatientReports;
