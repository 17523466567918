import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { lc } from "../../utils/helperFunctions";
import { historyCharts, historyVitals } from "../../utils/constants";
import { AxisTickStrategies, Themes, UIElementBuilders } from "@arction/lcjs";
import useFetchObservationsForCC from "../../utils/useFetchObservationsForCC";
import Button from "@mui/material/Button";
import "./HistoryCharts.css";

const HistoryCharts = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));

  const now = new Date();
  const currentUtcDateTime = now.toISOString();
  const fifteenMinutesLater = new Date(
    now.getTime() + 15 * 60 * 1000
  ).toISOString();
  const minus8Hours = new Date(
    now.getTime() - 8 * 60 * 60 * 1000
  ).toISOString();
  const fifteenMinutesEarlier = new Date(
    new Date(minus8Hours).getTime() - 15 * 60 * 1000
  ).toISOString();

  const historyData = useFetchObservationsForCC(
    minus8Hours,
    currentUtcDateTime
  );
  const [currentLayout, setCurrentLayout] = useState<string>("");

  function createEcgHeartRateChart(
    response: any,
    startInterval: string,
    endInterval: string
  ) {
    response?.sort(
      (a, b) =>
        new Date(a.resource.effectiveDateTime).getTime() -
        new Date(b.resource.effectiveDateTime).getTime()
    );

    const ecgChart = lc
      .ChartXY({
        theme: Themes.darkGold,
      })
      .setTitle(historyCharts.ecgHeartRate)
      .setMouseInteractions(false);

    ecgChart
      .getDefaultAxisX()
      .setInterval({
        start: new Date(startInterval).getTime(),
        end: new Date(endInterval).getTime(),
      })
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setMouseInteractions(false);

    ecgChart
      .getDefaultAxisY()
      .setInterval({ start: 0, end: 200 })
      .setMouseInteractions(false);

    let ecgHeartRate: any[] = [];

    response?.forEach((item) => {
      item?.resource?.component?.forEach((pastData) => {
        if (
          pastData?.code?.coding[0]?.code === historyCharts.ecgHeartRateCode
        ) {
          ecgHeartRate.push({
            x: new Date(item?.resource?.effectiveDateTime).getTime(),
            y: parseInt(pastData?.valueString),
          });
        }
      });
    });

    ecgChart
      .addPointLineSeries()
      .setName("")
      .setPointSize(10)
      .add(ecgHeartRate)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder
            .addRow(new Date(dataPoint.x).toLocaleString())
            .addRow(dataPoint.y.toFixed(1));
        }
      );

    const minLine = [
      { x: new Date(startInterval).getTime(), y: 60 },
      { x: new Date(endInterval).getTime(), y: 60 },
    ];

    ecgChart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(minLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Min:`, "", dataPoint.y.toFixed(1));
        }
      );

    const maxLine = [
      { x: new Date(startInterval).getTime(), y: 100 },
      { x: new Date(endInterval).getTime(), y: 100 },
    ];

    ecgChart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(maxLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Max:`, "", dataPoint.y.toFixed(1));
        }
      );

    ecgHeartRate?.map((item) => {
      return ecgChart
        .addUIElement(UIElementBuilders.TextBox, {
          x: ecgChart.getDefaultAxisX(),
          y: ecgChart.getDefaultAxisY(),
        })
        .setPosition({ x: item?.x, y: item?.y })
        .setVisible(true)
        .setText(item.y.toString())
        .setMouseInteractions(false);
    });

    const ecgChartContainer = document.getElementById("ecg-heart-rate-chart");
    if (ecgChartContainer) {
      ecgChartContainer.replaceChildren(ecgChart.engine.container);
      ecgChartContainer.onscroll = () => {
        ecgChart.engine.layout();
      };
    }
  }

  function createSystolicBpChart(
    response: any,
    startInterval: string,
    endInterval: string
  ) {
    response?.sort(
      (a, b) =>
        new Date(a.resource.effectiveDateTime).getTime() -
        new Date(b.resource.effectiveDateTime).getTime()
    );

    const systolicBpchart = lc
      .ChartXY({
        theme: Themes.darkGold,
      })
      .setTitle(historyCharts.systolicBP)
      .setMouseInteractions(false);

    systolicBpchart
      .getDefaultAxisX()
      .setInterval({
        start: new Date(startInterval).getTime(),
        end: new Date(endInterval).getTime(),
      })
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setMouseInteractions(false);

    systolicBpchart
      .getDefaultAxisY()
      .setInterval({ start: 50, end: 200 })
      .setMouseInteractions(false);

    let systolicBpValue: any[] = [];

    response?.forEach((item) => {
      item?.resource?.component?.forEach((pastData) => {
        if (pastData?.code?.coding[0]?.code === historyCharts.systolicBpValue) {
          systolicBpValue.push({
            x: new Date(item?.resource?.effectiveDateTime).getTime(),
            y: parseInt(pastData?.valueString),
          });
        }
      });
    });

    const minLine = [
      { x: new Date(startInterval).getTime(), y: 90 },
      { x: new Date(endInterval).getTime(), y: 90 },
    ];

    systolicBpchart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(minLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Min:`, "", dataPoint.y.toFixed(1));
        }
      );

    const maxLine = [
      { x: new Date(startInterval).getTime(), y: 150 },
      { x: new Date(endInterval).getTime(), y: 150 },
    ];

    systolicBpchart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(maxLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Max:`, "", dataPoint.y.toFixed(1));
        }
      );

    systolicBpchart
      .addPointLineSeries()
      .setName("")
      .setPointSize(10)
      .add(systolicBpValue)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          console.log(dataPoint.x.toFixed(1));
          return tableBuilder
            .addRow(new Date(dataPoint.x).toLocaleString())
            .addRow(dataPoint.y.toFixed(1));
        }
      );

    systolicBpValue?.map((item) => {
      return systolicBpchart
        .addUIElement(UIElementBuilders.TextBox, {
          x: systolicBpchart.getDefaultAxisX(),
          y: systolicBpchart.getDefaultAxisY(),
        })
        .setPosition({ x: item?.x, y: item?.y })
        .setVisible(true)
        .setText(item.y.toString())
        .setMouseInteractions(false);
    });

    const systolicBpChartContainer =
      document.getElementById("systolic-bp-chart");
    if (systolicBpChartContainer) {
      systolicBpChartContainer.replaceChildren(
        systolicBpchart.engine.container
      );
      systolicBpChartContainer.onscroll = () => {
        systolicBpchart.engine.layout();
      };
    }
  }

  function createDiastolicBpChart(
    response: any,
    startInterval: string,
    endInterval: string
  ) {
    response?.sort(
      (a, b) =>
        new Date(a.resource.effectiveDateTime).getTime() -
        new Date(b.resource.effectiveDateTime).getTime()
    );

    const diastolicBpchart = lc
      .ChartXY({
        theme: Themes.darkGold,
      })
      .setTitle(historyCharts.diastolicBP)
      .setMouseInteractions(false);

    diastolicBpchart
      .getDefaultAxisX()
      .setInterval({
        start: new Date(startInterval).getTime(),
        end: new Date(endInterval).getTime(),
      })
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setMouseInteractions(false);

    diastolicBpchart
      .getDefaultAxisY()
      .setInterval({ start: 20, end: 150 })
      .setMouseInteractions(false);

    let diastolicBpValue: any[] = [];

    response?.forEach((item) => {
      item?.resource?.component?.forEach((pastData) => {
        if (
          pastData?.code?.coding[0]?.code === historyCharts.diastolicBpValue
        ) {
          diastolicBpValue.push({
            x: new Date(item?.resource?.effectiveDateTime).getTime(),
            y: parseInt(pastData?.valueString),
          });
        }
      });
    });

    const minLine = [
      { x: new Date(startInterval).getTime(), y: 40 },
      { x: new Date(endInterval).getTime(), y: 40 },
    ];

    diastolicBpchart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(minLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Min:`, "", dataPoint.y.toFixed(1));
        }
      );

    const maxLine = [
      { x: new Date(startInterval).getTime(), y: 100 },
      { x: new Date(endInterval).getTime(), y: 100 },
    ];

    diastolicBpchart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(maxLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Max:`, "", dataPoint.y.toFixed(1));
        }
      );

    diastolicBpchart
      .addPointLineSeries()
      .setName("")
      .setPointSize(10)
      .add(diastolicBpValue)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          console.log(dataPoint.x.toFixed(1));
          return tableBuilder
            .addRow(new Date(dataPoint.x).toLocaleString())
            .addRow(dataPoint.y.toFixed(1));
        }
      );

    diastolicBpValue?.map((item) => {
      return diastolicBpchart
        .addUIElement(UIElementBuilders.TextBox, {
          x: diastolicBpchart.getDefaultAxisX(),
          y: diastolicBpchart.getDefaultAxisY(),
        })
        .setPosition({ x: item?.x, y: item?.y })
        .setVisible(true)
        .setText(item.y.toString())
        .setMouseInteractions(false);
    });

    const diastolicBpChartContainer =
      document.getElementById("diastolic-bp-chart");
    if (diastolicBpChartContainer) {
      diastolicBpChartContainer?.replaceChildren(
        diastolicBpchart.engine.container
      );
      diastolicBpChartContainer.onscroll = () => {
        diastolicBpchart.engine.layout();
      };
    }
  }

  function createSpo2Chart(
    response: any,
    startInterval: string,
    endInterval: string
  ) {
    response?.sort(
      (a, b) =>
        new Date(a.resource.effectiveDateTime).getTime() -
        new Date(b.resource.effectiveDateTime).getTime()
    );

    const sop2Chart = lc
      .ChartXY({
        theme: Themes.darkGold,
      })
      .setTitle(historyCharts.spo2)
      .setMouseInteractions(false);

    sop2Chart
      .getDefaultAxisX()
      .setInterval({
        start: new Date(startInterval).getTime(),
        end: new Date(endInterval).getTime(),
      })
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setMouseInteractions(false);

    sop2Chart
      .getDefaultAxisY()
      .setInterval({ start: 60, end: 150 })
      .setMouseInteractions(false);

    let spo2Value: any[] = [];

    response?.forEach((item) => {
      item?.resource?.component?.forEach((pastData) => {
        if (pastData?.code?.coding[0]?.code === historyCharts.spo2Value) {
          spo2Value.push({
            x: new Date(item?.resource?.effectiveDateTime).getTime(),
            y: parseInt(pastData?.valueString),
          });
        }
      });
    });

    const minLine = [
      { x: new Date(startInterval).getTime(), y: 90 },
      { x: new Date(endInterval).getTime(), y: 90 },
    ];

    sop2Chart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(minLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Min:`, "", dataPoint.y.toFixed(1));
        }
      );

    const maxLine = [
      { x: new Date(startInterval).getTime(), y: 100 },
      { x: new Date(endInterval).getTime(), y: 100 },
    ];

    sop2Chart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(maxLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Max:`, "", dataPoint.y.toFixed(1));
        }
      );

    sop2Chart
      .addPointLineSeries()
      .setName("")
      .setPointSize(10)
      .add(spo2Value)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          console.log(dataPoint.x.toFixed(1));
          return tableBuilder
            .addRow(new Date(dataPoint.x).toLocaleString())
            .addRow(dataPoint.y.toFixed(1));
        }
      );

    spo2Value?.map((item) => {
      return sop2Chart
        .addUIElement(UIElementBuilders.TextBox, {
          x: sop2Chart.getDefaultAxisX(),
          y: sop2Chart.getDefaultAxisY(),
        })
        .setPosition({ x: item?.x, y: item?.y })
        .setVisible(true)
        .setText(item.y.toString())
        .setMouseInteractions(false);
    });

    const spo2ChartContainer = document.getElementById("spo2-chart");
    if (spo2ChartContainer) {
      spo2ChartContainer.replaceChildren(sop2Chart.engine.container);
      spo2ChartContainer.onscroll = () => {
        sop2Chart.engine.layout();
      };
    }
  }

  function createRespiratoryValueChart(
    response: any,
    startInterval: string,
    endInterval: string
  ) {
    response?.sort(
      (a, b) =>
        new Date(a.resource.effectiveDateTime).getTime() -
        new Date(b.resource.effectiveDateTime).getTime()
    );

    const respiratoryValueChart = lc
      .ChartXY({
        theme: Themes.darkGold,
      })
      .setTitle(historyCharts.respiratoryValue)
      .setMouseInteractions(false);

    respiratoryValueChart
      .getDefaultAxisX()
      .setInterval({
        start: new Date(startInterval).getTime(),
        end: new Date(endInterval).getTime(),
      })
      .setTickStrategy(AxisTickStrategies.DateTime)
      .setMouseInteractions(false);

    respiratoryValueChart
      .getDefaultAxisY()
      .setInterval({ start: 0, end: 50 })
      .setMouseInteractions(false);

    let respiratoryValue: any[] = [];

    response?.forEach((item) => {
      item?.resource?.component?.forEach((pastData) => {
        if (
          pastData?.code?.coding[0]?.code === historyCharts.respiratoryValueCode
        ) {
          respiratoryValue.push({
            x: new Date(item?.resource?.effectiveDateTime).getTime(),
            y: parseInt(pastData?.valueString),
          });
        }
      });
    });

    const minLine = [
      { x: new Date(startInterval).getTime(), y: 8 },
      { x: new Date(endInterval).getTime(), y: 8 },
    ];

    respiratoryValueChart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(minLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Min:`, "", dataPoint.y.toFixed(1));
        }
      );

    const maxLine = [
      { x: new Date(startInterval).getTime(), y: 30 },
      { x: new Date(endInterval).getTime(), y: 30 },
    ];

    respiratoryValueChart
      .addLineSeries()
      .setStrokeStyle((style) => style.setThickness(2))
      .add(maxLine)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          return tableBuilder.addRow(`Max:`, "", dataPoint.y.toFixed(1));
        }
      );

    respiratoryValueChart
      .addPointLineSeries()
      .setName("")
      .setPointSize(10)
      .add(respiratoryValue)
      .setCursorResultTableFormatter(
        (tableBuilder, series, x, y, dataPoint) => {
          console.log(dataPoint.x.toFixed(1));
          return tableBuilder
            .addRow(new Date(dataPoint.x).toLocaleString())
            .addRow(dataPoint.y.toFixed(1));
        }
      );

    respiratoryValue?.map((item) => {
      return respiratoryValueChart
        .addUIElement(UIElementBuilders.TextBox, {
          x: respiratoryValueChart.getDefaultAxisX(),
          y: respiratoryValueChart.getDefaultAxisY(),
        })
        .setPosition({ x: item?.x, y: item?.y })
        .setVisible(true)
        .setText(item.y.toString())
        .setMouseInteractions(false);
    });

    const respiratoryValueChartContainer = document.getElementById(
      "respiratory-value-chart"
    );
    if (respiratoryValueChartContainer) {
      respiratoryValueChartContainer.replaceChildren(
        respiratoryValueChart.engine.container
      );
      respiratoryValueChartContainer.onscroll = () => {
        respiratoryValueChart.engine.layout();
      };
    }
  }

  useEffect(() => {
    if (currentLayout === historyCharts.ecgHeartRateCode) {
      createEcgHeartRateChart(
        historyData,
        fifteenMinutesEarlier,
        fifteenMinutesLater
      );
    } else if (currentLayout === historyCharts.systolicBpLayout) {
      createSystolicBpChart(
        historyData,
        fifteenMinutesEarlier,
        fifteenMinutesLater
      );
    } else if (currentLayout === historyCharts.diastolicBpLayout) {
      createDiastolicBpChart(
        historyData,
        fifteenMinutesEarlier,
        fifteenMinutesLater
      );
    } else if (currentLayout === historyCharts.spo2Value) {
      createSpo2Chart(historyData, fifteenMinutesEarlier, fifteenMinutesLater);
    } else if (currentLayout === historyCharts.respiratoryValueCode) {
      createRespiratoryValueChart(
        historyData,
        fifteenMinutesEarlier,
        fifteenMinutesLater
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLayout, patientDetails.patient_uhid]);

  return (
    <>
      <div id="layout-button-container">
        <Button
          id="ecg-button"
          variant="contained"
          className="vital-button"
          onClick={() => setCurrentLayout("ecgHeartRate")}
        >
          {historyVitals.ecgHeartRate}
        </Button>
        <Button
          id="systolic-bp-button"
          variant="contained"
          className="vital-button"
          onClick={() => setCurrentLayout("systolicBP")}
        >
          {historyVitals.systolicBP}
        </Button>
        <Button
          id="diastolic-bp-button"
          variant="contained"
          className="vital-button"
          onClick={() => setCurrentLayout("diastolicBP")}
        >
          {historyVitals.diastolicBP}
        </Button>
        <Button
          id="spo2-button"
          variant="contained"
          className="vital-button"
          onClick={() => setCurrentLayout("spo2")}
        >
          {historyVitals.spo2}
        </Button>
        <Button
          id="respiratory-value-button"
          variant="contained"
          className="vital-button"
          onClick={() => setCurrentLayout("respiratoryValue")}
        >
          {historyVitals.respiratoryRate}
        </Button>
      </div>
      <Box id="currently-viewing" sx={{ marginY: "10px", marginX: "15px" }}>
        {historyCharts.currentlyViewing}&nbsp;
        {currentLayout.length > 0 ? "" : historyCharts.none}
        {currentLayout.length > 0 &&
        currentLayout === historyCharts.ecgHeartRateCode
          ? historyVitals.ecgHeartRate
          : null}
        {currentLayout.length > 0 &&
        currentLayout === historyCharts.systolicBpLayout
          ? historyVitals.systolicBP
          : null}
        {currentLayout.length > 0 &&
        currentLayout === historyCharts.diastolicBpLayout
          ? historyVitals.diastolicBP
          : null}
        {currentLayout.length > 0 && currentLayout === historyCharts.spo2Value
          ? historyVitals.spo2
          : null}
        {currentLayout.length > 0 &&
        currentLayout === historyCharts.respiratoryValueCode
          ? historyVitals.respiratoryRate
          : null}
      </Box>
      {currentLayout === historyCharts.ecgHeartRateCode && (
        <div id="ecg-heart-rate-chart" className="history-chart"></div>
      )}
      {currentLayout === historyCharts.systolicBpLayout && (
        <div id="systolic-bp-chart" className="history-chart"></div>
      )}
      {currentLayout === historyCharts.diastolicBpLayout && (
        <div id="diastolic-bp-chart" className="history-chart"></div>
      )}
      {currentLayout === historyCharts.spo2Value && (
        <div id="spo2-chart" className="history-chart"></div>
      )}
      {currentLayout === historyCharts.respiratoryValueCode && (
        <div id="respiratory-value-chart" className="history-chart"></div>
      )}
    </>
  );
};

export default HistoryCharts;
