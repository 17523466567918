import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { calculateAge, titleCase } from "../../utils/helperFunctions";
import { patientDemographicData, years } from "../../utils/constants";
import "./PatientDetails.css";

const PatientDetails = () => {
  const patientDetails = useSelector((state: any) => ({
    patient_name: state.patient_name,
    patient_uhid: state.patient_uhid,
    patient_dob: state.patient_dob,
    patient_gender: state.patient_gender,
    patient_bed_number: state.patient_bed_number,
    patient_room_number: state.patient_room_number,
    patient_ward_name: state.patient_ward_name,
  }));

  return (
    <div id="patient-details-box" className="patient-details">
      <div id="patient-name-box" className="patient-demographic-detail">
        <Box id="patient-name-text" className="patient-demographic-type">
          {patientDemographicData.name}
        </Box>
        <Box id="patient-name" className="patient-demographic-name">
          <Tooltip
            id="patient-name-tooltip"
            title={titleCase(patientDetails.patient_name)}
            arrow
          >
            <span id="patient-name-value">
              {titleCase(patientDetails.patient_name)}
            </span>
          </Tooltip>
        </Box>
      </div>
      <div id="patient-id-box" className="patient-demographic-detail">
        <Box id="patient-id-text" className="patient-demographic-type">
          {patientDemographicData.uhid}
        </Box>
        <Box id="patient-id" className="patient-demographic-value">
          <Tooltip
            id="patient-id-tooltip"
            title={patientDetails.patient_uhid}
            arrow
          >
            <span id="patient-id-value">{patientDetails.patient_uhid}</span>
          </Tooltip>
        </Box>
      </div>
      <div id="patient-age-box" className="patient-demographic-detail">
        <Box id="patient-age-text" className="patient-demographic-type">
          {patientDemographicData.age}
        </Box>
        <Box id="patient-age" className="patient-demographic-value">
          <Tooltip
            id="patient-age-tooltip"
            title={calculateAge(patientDetails.patient_dob)}
            arrow
          >
            <span id="patient-age-value">
              {calculateAge(patientDetails.patient_dob)}&nbsp;{years}
            </span>
          </Tooltip>
        </Box>
      </div>
      <div id="patient-gender-box" className="patient-demographic-detail">
        <Box id="patient-gender-text" className="patient-demographic-type">
          {patientDemographicData.gender}
        </Box>
        <div id="patient-gender" className="patient-demographic-value">
          <Tooltip
            id="patient-gender-tooltip"
            title={titleCase(patientDetails.patient_gender)}
            arrow
          >
            <span id="patient-gender-value">
              {titleCase(patientDetails.patient_gender)}
            </span>
          </Tooltip>
        </div>
      </div>
      <div id="patient-ward-box" className="patient-demographic-detail">
        <Box id="patient-ward-text" className="patient-demographic-type">
          {patientDemographicData.ward}
        </Box>
        <div id="patient-ward" className="patient-demographic-value">
          <Tooltip
            id="patient-ward-tooltip"
            title={titleCase(patientDetails.patient_ward_name)}
            arrow
          >
            <span id="patient-ward-value">
              {titleCase(patientDetails.patient_ward_name)}
            </span>
          </Tooltip>
        </div>
      </div>
      <div id="patient-room-box" className="patient-demographic-detail">
        <Box id="patient-room-text" className="patient-demographic-type">
          {patientDemographicData.room}
        </Box>
        <div id="patient-room" className="patient-demographic-value">
          <Tooltip
            id="patient-room-tooltip"
            title={titleCase(patientDetails.patient_room_number)}
            arrow
          >
            <span id="patient-room-value">
              {titleCase(patientDetails.patient_room_number)}
            </span>
          </Tooltip>
        </div>
      </div>
      <div id="patient-bed-box" className="patient-demographic-detail">
        <Box id="patient-bed-text" className="patient-demographic-type">
          {patientDemographicData.bed}
        </Box>
        <div id="patient-bed" className="patient-demographic-value">
          <Tooltip
            id="patient-bed-tooltip"
            title={titleCase(patientDetails.patient_bed_number)}
            arrow
          >
            <span id="patient-bed-value">
              {titleCase(patientDetails.patient_bed_number)}
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default PatientDetails;
