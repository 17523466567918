import { createApiUrl } from "./helperFunctions";
import { CHAT_PROJECT_ID } from "./constants";
import { useDispatch } from "react-redux";
import { setLocationsList, setOnlyLocations } from "../redux/actions";

const useGetLocations = () => {
  const dispatch = useDispatch();

  function fetchGetLocations() {
    const payload = {
      intent: "getLocationsAPI",
    };
    fetch(createApiUrl(CHAT_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(
          setOnlyLocations(
            data?.entry
              ?.filter(
                (item: any) =>
                  item?.resource?.name === "Amalapuram" ||
                  item?.resource?.name === "Malkipuram" ||
                  item?.resource?.name === "Rajahmundry" ||
                  item?.resource?.name === "KIMS Amalapuram"
              )
              ?.map((location: any) => {
                return location?.resource?.name;
              })
              .sort()
          )
        );
        dispatch(
          setLocationsList(
            data?.entry?.map((location: any) => {
              return {
                id: location?.resource?.id,
                location: location?.resource?.name,
              };
            })
          )
        );
      })
      .catch((err) =>
        console.error("Error communicating with server. Error code: ", err)
      );
  }

  return { fetchGetLocations };
};

export default useGetLocations;
