interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index } = props;
  return (
    <div
      id="custom-tab-panel"
      hidden={value !== index}
      style={{ width: "100%" }}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export default CustomTabPanel;
