import { useDispatch } from "react-redux";
import { createApiUrl } from "./helperFunctions";
import { CRITICAL_BEDS_ID } from "./constants";
import {
  setPatientName,
  setPatientUHID,
  setPatientDOB,
  setPatientGender,
  setPatientFhirId,
  setPatientBedNumber,
  setPatientRoomNumber,
  setPatientWardName,
  setHasPatientDataLoaded,
} from "../redux/actions";

const useGetPatientDetails = () => {
  const dispatch = useDispatch();

  function fetchPatientDetails(patient_id: string) {
    const payload = {
      intent: "getPatientDetails",
      state: {
        patientIdentifier: patient_id,
      },
    };
    fetch(createApiUrl(CRITICAL_BEDS_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch(
          setPatientName(
            data?.firstName + " " + data?.middleName + " " + data?.lastName
          )
        );
        dispatch(setPatientUHID(data?.uhid));
        dispatch(setPatientDOB(data?.dateOfBirth));
        dispatch(setPatientGender(data?.gender));
        dispatch(setPatientFhirId(data?.fhirId));
        dispatch(setPatientBedNumber(data?.bed_number));
        dispatch(setPatientRoomNumber(data?.room_number));
        dispatch(setPatientWardName(data?.ward_name));
        dispatch(setHasPatientDataLoaded(true));
      })
      .catch((err) =>
        console.error("Error communicating with server. Error code: ", err)
      );
  }
  return { fetchPatientDetails };
};

export default useGetPatientDetails;
