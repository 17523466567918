import { Route, Routes } from "react-router-dom";
import Hospitals from "../Hospitals/Hospitals";
import Ambulance from "../Ambulance/Ambulance";
import HomeKare from "../HomeKare/HomeKare";
import PatientVideo from "../PatientVideo/PatientVideo";

const Navigator = () => {
  return (
    <Routes>
      <Route path="/" element={<Hospitals />} />
      <Route path="/ambulance" element={<Ambulance />} />
      <Route path="/homekare" element={<HomeKare />} />
      <Route path="/videoCall" element={<PatientVideo />} />
    </Routes>
  );
};

export default Navigator;
