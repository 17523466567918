import { useState, useEffect, SyntheticEvent } from "react";
import { DayProgress, MealProgress } from "./MedicationProgress";
import {
  noMedications,
  IPD_PROJECT_ID,
  OPD_PROJECT_ID,
  labReportMedications,
} from "../../../utils/constants";
import {
  action,
  titleCase,
  createApiUrl,
} from "../../../utils/helperFunctions";
import {
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Snackbar from "@mui/material/Snackbar";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import EventBusyOutlinedIcon from "@mui/icons-material/EventBusyOutlined";
import useGetAllSubEncounters from "../../../utils/useGetAllSubEncounters";
import "./Medications.css";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const Medications = () => {
  const allAppointments = useGetAllSubEncounters();
  const [allMedications, setAllMedications] = useState([]);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const [selectedMedication, setSelectedMedication] = useState<any>({});
  const [openDiscontinue, setOpenDiscontinue] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [refresh, setRefresh] = useState<boolean>(false);
  const [reasonForDiscontinue, setReasonForDiscontinue] = useState<string>("");

  const getSelectedDayIndexes = (selectedDays: []) => {
    const indexes = selectedDays?.map((d: any, i) => {
      switch (d?.value) {
        case "sunday":
          return 0;
        case "monday":
          return 1;
        case "tuesday":
          return 2;
        case "wednesday":
          return 3;
        case "thursday":
          return 4;
        case "friday":
          return 5;
        case "saturday":
          return 6;
        default:
          return -1;
      }
    });

    return indexes;
  };

  const handleMedicationBtnClick = (medication: any) => {
    setSelectedMedication(medication);
    setOpenDiscontinue(true);
  };

  const handleClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const fetchAllVisitMedications = (visitIds: number[]) => {
    const payloadForDocId = {
      intent: "getallVisitmedication",
      state: {
        visitIds: visitIds,
      },
    };
    fetch(createApiUrl(OPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForDocId),
    })
      .then((res) => res.json())
      .then((data) => {
        setAllMedications(data?.medications);
      })
      .catch((err) => console.log(err));
  };

  const discontinueMedication = async () => {
    setIsloading(true);

    const payloadForDocId = {
      intent: "editMedicationById",
      state: {
        id: selectedMedication?.id,
        status: "INACTIVE",
        discontinuedOn: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
        reason: reasonForDiscontinue,
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payloadForDocId),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setOpenSnackbar(true);
          setMessage(data?.message);
          setRefresh(!refresh);
          setIsloading(false);
          setOpenDiscontinue(false);
        } else {
          setMessage(data?.message);
          setIsloading(false);
        }
      })
      .catch((err) => {
        console.error(err, "err");
        setIsloading(false);
      });
  };

  useEffect(() => {
    if (allAppointments?.length > 0) {
      const encounterIds = allAppointments?.map((visitId: any) =>
        parseInt(visitId?.id, 10)
      );
      fetchAllVisitMedications(encounterIds);
    } else {
      setAllMedications([]);
    }
  }, [allAppointments, refresh]);

  return (
    <div
      id="medication-table-container"
      className="labReport-medications-table"
    >
      <TableContainer
        id="medications-table"
        component={Paper}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ background: "#004c91" }}>
                <Typography
                  id="sl-No"
                  align="left"
                  variant="body1"
                  className="slNo table-heading-text"
                >
                  {labReportMedications.slNo}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="drug-name-text"
                  variant="body1"
                  className="drug-name table-heading-text"
                >
                  {labReportMedications.drugName}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="dose"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.dose}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="dose-units"
                  variant="body1"
                  className="dose-units table-heading-text"
                >
                  {labReportMedications.doseUnits}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="duration"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.duration}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="frequency"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.frequency}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="route"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.route}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="start-date"
                  variant="body1"
                  className="dates table-heading-text"
                >
                  {labReportMedications.startDate}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="end-date"
                  variant="body1"
                  className="dates table-heading-text"
                >
                  {labReportMedications.endDate}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="intervals"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.intervals}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="additional-instructions"
                  variant="body1"
                  className="add-instructions table-heading-text"
                >
                  {labReportMedications.addInstructions}
                </Typography>
              </TableCell>
              <TableCell sx={{ background: "#004c91" }} align="left">
                <Typography
                  id="additional-instructions"
                  variant="body1"
                  className="table-heading-text"
                >
                  {labReportMedications.actions}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allMedications?.length > 0 ? (
              <>
                {allMedications?.map((medication: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Typography
                          id="serial-number"
                          align="left"
                          variant="body2"
                        >
                          {index + 1}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="drug-name-value" variant="body2">
                          {titleCase(medication?.drug_name)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="dose-value" variant="body2">
                          {medication?.dose}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="dose-units-value" variant="body2">
                          {titleCase(medication?.dose_units)}(s)
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="duration-units-value" variant="body2">
                          {medication?.duration}&nbsp;
                          {medication.duration_units}(s)
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="frequency-value" variant="body2">
                          {titleCase(medication?.frequency)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="route-value" variant="body2">
                          {titleCase(medication?.route)}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="start-date-value" variant="body2">
                          {medication?.start_date}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography id="end-date-value" variant="body2">
                          {medication?.next_visit_date ?? "-"}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {medication?.intervals?.length > 0 ? (
                          <div
                            id="intervals-container"
                            className="drug-flexRow"
                          >
                            {(medication?.frequency === "Immediately" ||
                              medication?.frequency === "Once a day" ||
                              medication?.frequency === "Twice a day" ||
                              medication?.frequency === "Five times a day" ||
                              medication?.frequency === "Thrice a day" ||
                              medication?.frequency === "Four times a day") && (
                              <MealProgress
                                activeMeals={[
                                  {
                                    meal: "breakfast",
                                    time: medication.intervals.some(
                                      (option: any) => option?.value === "bb"
                                    )
                                      ? "before"
                                      : medication.intervals.some(
                                          (option: any) =>
                                            option?.value === "ab"
                                        )
                                      ? "after"
                                      : "",
                                  },
                                  {
                                    meal: "lunch",
                                    time: medication.intervals.some(
                                      (option: any) => option?.value === "bl"
                                    )
                                      ? "before"
                                      : medication.intervals.some(
                                          (option: any) =>
                                            option?.value === "al"
                                        )
                                      ? "after"
                                      : "",
                                  },
                                  {
                                    meal: "dinner",
                                    time: medication.intervals.some(
                                      (option: any) => option?.value === "bd"
                                    )
                                      ? "before"
                                      : medication.intervals.some(
                                          (option: any) =>
                                            option?.value === "ad"
                                        )
                                      ? "after"
                                      : "",
                                  },
                                ]}
                              />
                            )}
                            {(medication?.frequency === "On alternate days" ||
                              medication?.frequency === "Once a week" ||
                              medication?.frequency === "Twice a week" ||
                              medication?.frequency === "Thrice a week" ||
                              medication?.frequency === "Four days a week" ||
                              medication?.frequency === "Five days a week" ||
                              medication?.frequency === "Six days a week") && (
                              <DayProgress
                                activeDays={getSelectedDayIndexes(
                                  medication.intervals
                                )}
                              />
                            )}
                            {medication?.duration_units === "hour" &&
                              medication?.intervals[0]?.value}
                            {medication?.duration_units === "Every 2 weeks" &&
                              medication?.intervals[0]?.value}
                            {medication?.duration_units === "Every 3 weeks" &&
                              medication?.intervals[0]?.value}
                            {medication?.frequency === "Day(s)" ||
                              medication?.frequency === "Week(s)" ||
                              (medication?.frequency === "Month(s)" &&
                                medication?.intervals[0]?.value)}
                          </div>
                        ) : (
                          "-"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          id="add-instructions-value"
                          sx={{ typography: "body2" }}
                        >
                          <Box>
                            {titleCase(medication?.instructions || "-")}
                          </Box>
                          <Box>
                            {titleCase(
                              medication?.additional_instructions || "-"
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          disabled={
                            Boolean(medication?.status === "INACTIVE") ||
                            isLoading
                          }
                          variant="contained"
                          size="small"
                          onClick={() => handleMedicationBtnClick(medication)}
                          startIcon={<EventBusyOutlinedIcon fontSize="small" />}
                        >
                          {labReportMedications.discontinue}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={5}>
                  <b id="no-medications">{noMedications}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        disableScrollLock
        id="discontinue-dialog"
        open={openDiscontinue}
        onClose={() => setOpenDiscontinue(false)}
      >
        <DialogTitle id="discontinue-title">
          <span>{labReportMedications.discontinueConfirm}</span>
          &nbsp;
          <span>{selectedMedication?.drug_name}</span>
          &nbsp;?
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={3}
            fullWidth
            placeholder="Enter your reason"
            onChange={(e) => setReasonForDiscontinue(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            id="discontinue-btn-yes"
            variant="contained"
            size="small"
            onClick={discontinueMedication}
          >
            {labReportMedications.yes}
          </Button>
          <Button
            id="discontinue-btn-no"
            variant="contained"
            size="small"
            onClick={() => setOpenDiscontinue(false)}
          >
            {labReportMedications.no}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        id="snackbar-message"
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message}
        action={action(handleClose)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </div>
  );
};

export default Medications;
