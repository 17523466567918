import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { treatment, naText, IPD_PROJECT_ID } from "../../../utils/constants";
import { iReferralData } from "../../../interfaces";
import {
  createApiUrl,
  createFormApiUrl,
  formatReadableDate,
  filterEncountersByDate,
} from "../../../utils/helperFunctions";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DatePicker from "react-datepicker";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableContainer from "@mui/material/TableContainer";
import "./TreatmentPlan.css";

const TreatmentCard = ({
  text,
  showFullText,
  setShowFullText,
}: {
  text: string;
  showFullText?: boolean;
  setShowFullText?: () => void;
}) => {
  return (
    <div id="treatment-card-box" className="treatment-card">
      {text?.length > 300 ? (
        <p id="treatment-type-value">
          {showFullText ? text : text?.substring(0, 300)}
          <span
            id="treatment-show-more-button"
            className="show-more"
            onClick={setShowFullText}
          >
            {showFullText ? (
              <span className="showless-text-treatment">
                &nbsp;&nbsp;&nbsp;{treatment.showLess}
              </span>
            ) : (
              <span className="showless-text-treatment">
                &nbsp;&nbsp;&nbsp;{treatment.showMore}
              </span>
            )}
          </span>
        </p>
      ) : (
        text
      )}
    </div>
  );
};

function TreatmentPlan() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const patientDetails = useSelector((state: any) => ({
    patient_uhid: state.patient_uhid,
  }));
  const [open, setOpen] = useState<boolean>(false);
  const [showFullTexts, setShowFullTexts] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [finalTreatmentPlanData, setFinalTreatmentPlanData] = useState<any[]>(
    []
  );
  const [treatmentPlanSearchData, setTreatmentPlanSearchData] = useState<any[]>(
    []
  );
  const [treatmentDetails, setTreatmentDetails] = useState<iReferralData>({
    department: "",
    encounterId: "",
    nameOfDoctor: "",
    reasonReferral: "",
    referralRequired: "",
    treatmentPlan: "",
  });

  const getAllTreatmentPlans = () => {
    const payload = {
      intent: "getEncounterAndDocName",
      state: {
        patId: patientDetails?.patient_uhid,
      },
    };
    fetch(createApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setFinalTreatmentPlanData(data);
        setTreatmentPlanSearchData(data);
      })
      .catch((err) => console.error(err));
  };

  const getTreatmentPlansDetails = async (id: any) => {
    const payload = {
      intent: "treatmentPlanForIPD",
      state: {
        encounterId: id,
      },
      submit: false,
    };
    fetch(createFormApiUrl(IPD_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setOpen(true);
        setTreatmentDetails(data?.state);
      })
      .catch((err) => console.error(err));
  };

  const handleDialogClose = () => {
    setOpen(false);
    setTreatmentDetails({
      department: "",
      encounterId: "",
      nameOfDoctor: "",
      reasonReferral: "",
      referralRequired: "",
      treatmentPlan: "",
    });
  };

  const dateOnChange = (date: Date) => {
    setSelectedDate(date);
  };

  const handleDataFilterWithDate = () => {
    const data = filterEncountersByDate(treatmentPlanSearchData, selectedDate);
    setFinalTreatmentPlanData(data);
  };

  useEffect(() => {
    getAllTreatmentPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientDetails?.patient_uhid]);

  useEffect(() => {
    handleDataFilterWithDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div>
      <div id="date-picker" className="date-picker-container">
        <span id="select-date-text">{treatment.selectDate}:&nbsp;</span>
        <DatePicker
          id="date-picker-input"
          placeholderText="DD-MM-YYYY"
          selected={selectedDate}
          onChange={dateOnChange}
          dateFormat="dd-MM-yyyy"
        />
      </div>
      <div id="treatment-table-box" className="treatment-table">
        <TableContainer
          id="treatment-table-container"
          component={Paper}
          sx={{ maxHeight: 440 }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="sl-no"
                    align="center"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{treatment.slNo}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }}>
                  <Typography
                    id="date"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{treatment.date}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="left">
                  <Typography
                    id="doctor-name"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{treatment.doctorName}</b>
                  </Typography>
                </TableCell>
                <TableCell sx={{ background: "#004c91" }} align="center">
                  <Typography
                    id="view"
                    variant="body1"
                    className="table-heading-text"
                  >
                    <b>{treatment.view}</b>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalTreatmentPlanData?.length > 0 ? (
                <>
                  {finalTreatmentPlanData?.map((item: any, index: number) => {
                    return (
                      <TableRow key={item?.encounterId}>
                        <TableCell align="center">
                          <Typography id="row-sl-no" variant="body2">
                            {index + 1}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography id="date" variant="body2">
                            {formatReadableDate(item?.date)}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography id="practitioner-name" variant="body2">
                            {item?.practitioner_name}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Button
                            id="view-button"
                            variant="contained"
                            onClick={() =>
                              getTreatmentPlansDetails(item?.encounterId)
                            }
                          >
                            {treatment.view}
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <b id="no-data">{treatment.noData}</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Dialog
        id="view-dialog"
        fullScreen={fullScreen}
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="treatment-details"
      >
        <DialogTitle id="treatment-details">
          {treatment?.treatmentSuggestions}
        </DialogTitle>
        <DialogContent>
          <div id="provisional-treatment-box">
            <p id="provisional-treatment-text">
              <b>{treatment?.treatmentPlan}:</b>
            </p>
            {treatmentDetails?.treatmentPlan ? (
              <TreatmentCard
                text={treatmentDetails?.treatmentPlan}
                showFullText={showFullTexts}
                setShowFullText={() => setShowFullTexts(!showFullTexts)}
              />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
          <div id="provisional-treatment-box">
            <p id="provisional-treatment-text">
              <b>{treatment?.referralRequired}:</b>
            </p>
            {treatmentDetails?.referralRequired ? (
              <TreatmentCard text={treatmentDetails?.referralRequired} />
            ) : (
              <span id="na-text">{naText}</span>
            )}
          </div>
          {treatmentDetails?.referralRequired === "yes" && (
            <>
              <div id="provisional-treatment-box">
                <p id="provisional-treatment-text">
                  <b>{treatment?.nameOfDoctor}:</b>
                </p>
                {treatmentDetails?.nameOfDoctor ? (
                  <TreatmentCard
                    text={treatmentDetails.nameOfDoctor.split("::")[1]}
                  />
                ) : (
                  <span id="na-text">{naText}</span>
                )}
              </div>
              <div id="provisional-treatment-box">
                <p id="provisional-treatment-text">
                  <b>{treatment?.department}:</b>
                </p>
                {treatmentDetails?.department ? (
                  <TreatmentCard text={treatmentDetails?.department} />
                ) : (
                  <span id="na-text">{naText}</span>
                )}
              </div>
              <div id="provisional-treatment-box">
                <p id="provisional-treatment-text">
                  <b>{treatment?.reasonReferral}:</b>
                </p>
                {treatmentDetails?.reasonReferral ? (
                  <TreatmentCard text={treatmentDetails?.reasonReferral} />
                ) : (
                  <span id="na-text">{naText}</span>
                )}
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button id="close-button" onClick={handleDialogClose} autoFocus>
            {treatment.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TreatmentPlan;
