import { useEffect, useRef } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";

const RenderOnAuthenticated = ({ children }) => {
  const { keycloak } = useKeycloak();
  const navigate = useNavigate();
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;

      if (window.location.pathname !== "/videoCall") {
        navigate("/");
      }
    }
  }, [navigate]);

  return <>{keycloak.authenticated && <>{children}</>}</>;
};

export default RenderOnAuthenticated;
