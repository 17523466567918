import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  List,
  ListItem,
} from "@mui/material";
import {
  keepEditingText,
  labSuggestionsText,
  medications,
  medicationsTable,
  medicationSubmitAlert,
  naText,
  submit,
  tests,
  treatmentsText,
} from "../../../utils/constants";
import { useTheme } from "@mui/material/styles";
import { MedicineData } from "../../../interfaces";
import { titleCase } from "../../../utils/helperFunctions";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./MedicationConfirmationDialog.css";

interface MedicationConfirmationDialogProps {
  open: boolean;
  handleClose: () => void;
  medicineData: MedicineData[];
  onSubmit: (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>
  ) => void;
  testData: string[];
  labSuggestions: String;
}

const MedicationConfirmationDialog: React.FC<
  MedicationConfirmationDialogProps
> = ({
  open,
  handleClose,
  medicineData,
  onSubmit,
  testData,
  labSuggestions,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fullScreen}
      fullWidth
      id="medication-confirmation-dialog"
      aria-labelledby="medicine-details"
      maxWidth={"lg"}
    >
      <DialogTitle className="dialog-title">
        {medicationSubmitAlert}
      </DialogTitle>
      <DialogContent className="dialog-content">
        <Typography variant="h6">{tests}:</Typography>
        {testData?.length > 0 ? (
          <List id="tests-list">
            {testData?.map((test, index) => (
              <ListItem id="test-list-items" key={test}>
                <Typography id="medication-tests" variant="body1">
                  {index + 1}.&nbsp;{test}
                </Typography>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography id="na-tests" fontStyle={"italic"}>
            {naText}
          </Typography>
        )}
        <Typography variant="h6">{medications}:</Typography>
        {medicineData?.length > 0 ? (
          <div id="medication-table-box" className="medicines-table">
            <TableContainer id="add-medications-table" component={Paper}>
              <Table id="medication-table">
                <TableHead>
                  <TableRow>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="medicine-name-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.medicineName}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="dose-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.dose}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="unit-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.unit}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="route-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.route}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="frequency-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.frequency}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="interval-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.interval}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="duration-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.duration}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="total-quantity-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.totalQty}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="start-date-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.startDate}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="end-date-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.endDate}</b>
                      </Typography>
                    </TableCell>
                    <TableCell className="medication-table-cell" align="center">
                      <Typography
                        id="sos-text"
                        variant="body1"
                        className="table-heading-text"
                      >
                        <b>{medicationsTable?.sos}</b>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="medication-table-body">
                  {medicineData?.length > 0
                    ? medicineData?.map((row: any, index) => {
                        return (
                          <TableRow id="medicine-details" key={row?.unit}>
                            <TableCell id="medication-name-value">
                              {row?.drugName}
                            </TableCell>
                            <TableCell id="medication-dose-value">
                              {row?.dose}
                            </TableCell>
                            <TableCell id="medication-unit-value">
                              {titleCase(row?.unit)}
                            </TableCell>
                            <TableCell id="medication-route-value">
                              {titleCase(row?.route)}
                            </TableCell>
                            <TableCell id="medication-frequency-value">
                              {row?.frequency}
                            </TableCell>
                            <TableCell id="medication-interval-value">
                              <div
                                id="medicine-intervals-container"
                                className="select-interval"
                              >
                                {row?.frequency &&
                                  (row?.frequency === "Once a day" ||
                                    row?.frequency === "Immediately" ||
                                    row?.frequency === "Twice a day" ||
                                    row?.frequency === "Thrice a day" ||
                                    row?.frequency === "Four times a day" ||
                                    row?.frequency === "Five times a day") && (
                                    <>
                                      <div
                                        id="breakfast-checkboxes-container"
                                        className="drug-flexColumn"
                                      >
                                        <label id="breakfast-dose-label">
                                          {treatmentsText.breakfast}
                                        </label>
                                        <div
                                          id="breakfast-dose-checkbox"
                                          className="drug-flexRow"
                                        >
                                          <input
                                            id="before-breakfast"
                                            type="checkbox"
                                            name="breakfast"
                                            value="bb"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name === "breakfast" &&
                                                option?.value === "bb"
                                            )}
                                          />
                                          <input
                                            id="after-breakfast"
                                            type="checkbox"
                                            name="breakfast"
                                            value="ab"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name === "breakfast" &&
                                                option?.value === "ab"
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        id="lunch-checkboxes-box"
                                        className="drug-flexColumn"
                                      >
                                        <label id="lunch-label">
                                          {treatmentsText?.lunch}
                                        </label>
                                        <div
                                          id="lunch-checkboxes"
                                          className="drug-flexRow"
                                        >
                                          <input
                                            id="before-lunch-checkbox"
                                            type="checkbox"
                                            name="before lunch"
                                            value="bl"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name ===
                                                  "before lunch" &&
                                                option?.value === "bl"
                                            )}
                                          />
                                          <input
                                            id="after-lunch"
                                            type="checkbox"
                                            name="after lunch"
                                            value="al"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name ===
                                                  "after lunch" &&
                                                option?.value === "al"
                                            )}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        id="dinner-checkboxes-box"
                                        className="drug-flexColumn"
                                      >
                                        <label id="dinner-text">
                                          {treatmentsText?.dinner}
                                        </label>
                                        <div
                                          id="dinner-checkboxes"
                                          className="drug-flexRow"
                                        >
                                          <input
                                            id="before-dinner"
                                            type="checkbox"
                                            name="before dinner"
                                            value="bd"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name ===
                                                  "before dinner" &&
                                                option?.value === "bd"
                                            )}
                                          />
                                          <input
                                            id="after-dinner"
                                            type="checkbox"
                                            name="after dinner"
                                            value="ad"
                                            readOnly
                                            checked={row?.radioOptions?.some(
                                              (option: {
                                                name: string;
                                                value: string;
                                              }) =>
                                                option?.name ===
                                                  "after dinner" &&
                                                option?.value === "ad"
                                            )}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                              </div>
                            </TableCell>
                            <TableCell id="medication-duration-units">
                              {row?.duration}&nbsp;{row?.durationUnits}(s)
                            </TableCell>
                            <TableCell id="medication-quantities-value">
                              {row?.quantities}
                            </TableCell>
                            <TableCell id="medication-start-date">
                              {row?.startDate}
                            </TableCell>
                            <TableCell id="medication-end-date">
                              {row?.endDate}
                            </TableCell>
                            <TableCell id="medication-sos">
                              {row?.sos ? "Yes" : "No"}&nbsp;{row?.instructions}
                              &nbsp;
                              {row?.additional}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          <Typography id="na-medicine" fontStyle={"italic"}>
            {naText}
          </Typography>
        )}
        <Typography variant="h6" className="lab-suggestions-header">
          {labSuggestionsText}:
        </Typography>
        {labSuggestions?.length > 0 ? (
          <div className="resizable-textarea-container">
            <textarea readOnly value={labSuggestions.toString()} />
          </div>
        ) : (
          <Typography id="na-lab-suggestions" fontStyle={"italic"}>
            {naText}
          </Typography>
        )}
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button
          id="keep-editing-button"
          onClick={handleClose}
          color="warning"
          variant="contained"
        >
          {keepEditingText}
        </Button>
        <Button
          id="confirm-submit-button"
          onClick={(e) => onSubmit(e)}
          color="success"
          variant="contained"
        >
          {submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MedicationConfirmationDialog;
