import * as jose from "jose";
import { JITSI_SECRET_KEY } from "./constants";
import { jitsiTokenEndpoint } from "./endpoints";

async function decodeJwe(key: string) {
  let jweToken = "";
  const secretKey = new TextEncoder().encode(JITSI_SECRET_KEY);

  try {
    const { plaintext } = await jose.compactDecrypt(key, secretKey);
    jweToken = new TextDecoder().decode(plaintext);
  } catch (error) {
    console.error(error);
  }
  return jweToken;
}

export async function fetchJitsiToken() {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DMS_API_URL}${jitsiTokenEndpoint}`
    );
    if (!response.ok) {
      throw new Error(`Failed to fetch token, status code: ${response.status}`);
    }
    const key = await response.text();
    if (key) {
      const jwtToken = await decodeJwe(key);
      return jwtToken;
    }
    throw new Error("Failed to fetch Jitsi token");
  } catch (error) {
    console.error(error);
    return "";
  }
}
