import { useEffect, useState } from "react";
import { iTreatmentSuggestions } from "../interfaces";
import { LABS_PROJECT_ID } from "./constants";
import { createApiUrl } from "./helperFunctions";

const useGetTestPanels = () => {
  const [panelsList, setPanelsList] = useState<string[]>([]);
  const [allPanels, setAllPanels] = useState<iTreatmentSuggestions[]>([]);

  function fetchPanels() {
    const payload = {
      intent: "getAllPanelsApi",
      state: {},
    };
    fetch(createApiUrl(LABS_PROJECT_ID), {
      method: "POST",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("react-token")}`,
        "Content-Type": "application/json",
        version: "WORKING",
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then((data) => {
        setAllPanels(data?.panel);
        setPanelsList(
          data?.panel?.map((item: iTreatmentSuggestions) => item?.name) ||
            panelsList
        );
      })
      .catch((err) => {
        console.error("Error communicating with server. Error code: ", err);
      });
  }

  useEffect(() => {
    fetchPanels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { panelsList, allPanels };
};

export default useGetTestPanels;
